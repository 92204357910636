
import http from '../../http';
export function userList (params) {
    return http({
        url: "/api/user/userList",
        method: "get",
        params:params
    })
}
export function resetpwd (data) {
    return http({
        url: "/api/user/resetpwd",
        method: "post",
        data:data
    })
}
export function refresh (data) {
    return http({
        url: "/api/user/refresh",
        method: "post",
        data:data
    })
}



