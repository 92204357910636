import http from '../http';

export function login (data) {
    return http({
        url: "/api/user/login",
        method: "post",
        data:data
    })
}

export function register (data) {
    return http({
        url: "/api/user/register",
        method: "put",
        data:data
    })
}

export function registerUserInfo (data) {
    return http({
        url: "/api/ltd/userInfoUserCreateAndUpdate",
        method: "put",
        data:data
    })
}

export function registerUserEdu (data) {
    return http({
        url: "/api/ltd/userEducationUserCreateAndUpdate",
        method: "put",
        data:data
    })
}

export function registerUserWork (data) {
    return http({
        url: "/api/ltd/userWorkUserCreateAndUpdate",
        method: "put",
        data:data
    })
}

//获取左侧菜单列表
export function jurisdictionAll (params) {
    return http({
        url: "/api/ltd/jurisdictionAll",
        method: "get",
        params:params
    })
}

export function isLogin (params) {
    return http({
        url: "/api/user/isLogin",
        method: "get",
        params:params
    })
}

