import http from '../http';

export function getDoc (params) {
    return http({
        url: "/api/fileManage/projectFilePage",
        method: "get",
        params:params
    })
}

export function getGsDoc (params) {
    return http({
        url: "/api/fileManage/fileManagePage",
        method: "get",
        params:params
    })
}

export function addDoc (data) {
    return http({
        url: "/api/fileManage/fileManage",
        method: "put",
        data:data
    })
}

export function delDoc (data) {
    return http({
        url: "/api/fileManage/fileManage",
        method: "delete",
        data:data
    })
}
