<template>
  <div>
    <div class="head-top">
      <div class="head-top-left">

        <el-button style="padding: 0;font-size: 28px;border-radius: 50%" icon="el-icon el-icon-caret-left" @click="back" type="primary"></el-button>

      </div>
<!--      <h1 style="color: #3A66E2;font-weight: bold">华创内部项目管理系统</h1>-->
      <div style="display: flex">
        <i class="el-icon-bell" @click="toMsg">
          <div v-if="$store.state.notice_num !== 0">{{$store.state.notice_num}}</div>
        </i>
        <el-dropdown class="head-top-dropdown"  @command="handleCommand">
          <div class="head-top-dropdown-img">
            <img :src="avatar">
            <span>{{ admin_name }}</span>
          </div>
          <el-dropdown-menu slot="dropdown" >
            <el-dropdown-item command="edit">修改</el-dropdown-item>
            <el-dropdown-item command="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!--addDialog 组件 -->
      <common-add-dialog
          :addDialogData="addDialogData"
          @addDialogSub="addDialogSub"
      />
    </div>
<!--    <el-scrollbar>-->
<!--      <div class="head-tag">-->

<!--      </div>-->
<!--    </el-scrollbar>-->
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {getDetail} from "../../api/module/user/personal_func";
import {resetpwd} from "../../api/module/common/common";
import {getMsgCount} from "@/api/home/home";
export default {
  components: {},
  props: [],
  data() {
    return {
      admin_name:'暂未设置用户名',
      avatar:require('@/assets/images/avatar_default.png'),
      //addDialog数据源
      addDialogData: {
        isDialog: false,//dialog是否显示
        subMethod: 'addDialogSub',//点击确定方法名
        title: '123',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '900px', // dialog 长度，可以是百分比
        labelWidth: '160px', // label 长度
        //dialog 数据源
        addDialogProp: [{
          label: '旧密码',
          field: 'old_password',
          type: 15,
          show: true,
        },{
          label: '新密码',
          field: 'password',
          type: 15,
          show: true,
        },{
          label: '重复密码',
          field: 'password_confirmation',
          type: 15,
          show: true,
        },],
        //dialog 显示数据
        addDialogShowData: {
          password:'',
          old_password:'',
          password_confirmation:'',
        },

      },
    };
  },
  computed: {
    ...mapState('layout', {
      layoutState: state => state
    }),
  },
  created() {
    //获取当前用户信息
    getDetail({}).then(result=>{
      let adminInfo = {};
      if(result.data.user_info){
        adminInfo.avatar = JSON.parse(result.data.user_info.avatar)
        this.avatar = adminInfo.avatar.img[0]

      }else {
        adminInfo.avatar = { //图片要用
          isAll: false,//是否多图上传 不是默认是否， 需要多图上传改成true
          img: [require('@/assets/images/avatar_default.png')],
        }
      }
      if(result.data.user_info) {
        this.admin_name = result.data.user_info.name
      }

    })
  //  未读消息数量
    getMsgCount().then(res=>{
      this.$store.state.notice_num = res.data.notice_num
    })
  },
  methods: {

    back(){
      this.$router.back()
    },
    edit(){
      this.addDialogData.title = '修改密码';
      this.addDialogData.isDialog = true;
    },
    addDialogSub() {
      let data = this.addDialogData.addDialogShowData
      resetpwd(data).then(res=>{
        this.$message({
          type: 'success',
          message: "修改成功，退出重新登陆"
        });
        console.log(res)
        this.addDialogData.isDialog = false;
        this.logout()
      })
    },
    handleCommand(command){
      if(command == 'logout'){
        this.logout()
      }
      if(command == 'edit'){
        this.edit()
      }
    },
    logout(){
      localStorage.clear();
      this.$router.push('/login')
    },
    //left 是否缩进
    isCollapseCutover() {
      this.layoutState.isCollapse = !this.layoutState.isCollapse;
    },
    //tag 点击
    clickTag(path) {
      this.$router.push(path);
    },
    //tag 删除
    clostTag(key) {
      //删除的是否是当前的路由
      if (this.$route.path == this.layoutState.tag[key].path) {
        this.$router.push(this.layoutState.tag[key - 1].path);
      }
      this.layoutState.tag.splice(key, 1);
    },
  //  跳转消息列表
    toMsg(){
      this.$router.push('/home/message');
    }
  }
};
</script>
<style lang="scss">
.head-tag {
  box-shadow: $box-shadow-base;
  padding-bottom: 4px;
  white-space: nowrap;
  margin: 4px 0;
  //overflow-x: auto;
}
</style>

<style lang="scss" scoped>
.head-top {
  padding: 8px 5px;
  @include flex( nowrap, space-between,);

  &-left {
    @include flex();
    //是否缩进图标
    & i {
      font-size: 22px;
      font-weight: normal;
      padding-right: 12px;
    }
  }

  //用户账号 图片
  &-dropdown {
    color: white;
    margin-right: 30px;
    &-img {
      @include flex();

      img {
        border-radius: 20%;
        height: 40px;
      }

      span {
        padding-left: 8px;
      }
    }

  }
  //消息通知
  .el-icon-bell{
    font-size: 30px;
    margin-right: 20px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    div{
      height: 20px;
      width: 20px;
      background-color: red;
      position: absolute;
      bottom: 0;
      right: -7px;
      border-radius: 50%;
      color: white;
      font-size: 13px;
      text-align: center;
      line-height: 20px;
    }
  }
}

</style>
