<template>
  <div>
    <div style="padding: 20px 0;text-align: center; position: fixed; top: 0; z-index: 999; width: 200px">
      <img :src="require('@/assets/images/left_logo.png')" alt="">
    </div>
    <div style="margin-top: 150px; z-index: 990;">
      <el-scrollbar wrap-class="scrollbar-x">
        <div style="height: calc(100vh - 150px)">
          <!--    权限为全部的 -->
          <template v-if="authority.indexOf('*')!=-1" >
            <!--        background-color="#3a66e2"-->
            <el-menu
                class="layoutLeft-menu"
                default-active="/hmoe"
                text-color="rgb(191, 203, 217)"
                active-text-color="#1890ff"
                router
                @close="close"
                @open="open"
            >
              <template v-for="(item,key) in authMenuList">
                <div :key="key">
                  <!--只有一级菜单-->
                  <template v-if="item.path!=undefined">
                    <el-menu-item :index="item.path">
                      <img  style="margin-right: 15px" :src="require('@/assets/images/'+item.icon+'.png')">
                      <span slot="title">{{ item.title }}</span>
                    </el-menu-item>
                  </template>

                  <!--有二级或三级菜单的标题-->
                  <template v-if="item.path==undefined">
                    <el-submenu :index="(key).toString()">
                      <template slot="title">
                        <div class="left-title">
                          <img  style="margin-right: 15px" :src="require('@/assets/images/'+item.icon+'.png')">
                          <span>{{ item.title }}</span>
                        </div>
                      </template>

                      <!--没有三级菜单-->
                      <template>
                        <el-menu-item-group>
                          <div v-for="(twoItem,twoKey) in item.children" :key="twoKey" >
                            <el-menu-item :index="twoItem.path"  style="padding-left: 15px">{{ twoItem.title }}</el-menu-item>
                          </div>
                        </el-menu-item-group>
                      </template>


                    </el-submenu>
                  </template>
                </div>
              </template>
            </el-menu>
          </template>
          <template v-else>
            <!--        background-color="#3a66e2"-->
            <el-menu
                class="layoutLeft-menu"
                :default-active="$route.path"
                text-color="#fff"
                background-color="rgb(0 0 0 / 0%)"
                active-text-color="#FFFFFF"
                router
                @close="close"
                @open="open"
            >

              <template v-for="(item,key) in authMenuList">
                <div :key="key">
                  <!--只有一级菜单-->

                  <template v-if="item.children==undefined">
                    <el-menu-item :index="item.path">
                      <img style="margin-right: 15px" :src="require('@/assets/images/'+item.icon+'.png')">
                      <span slot="title">{{ item.title }}</span>
                    </el-menu-item>
                  </template>
                  <!--有二级或三级菜单的标题-->
                  <template v-if="item.children!=undefined && authority.indexOf(item.id)!=-1">
                    <el-submenu :index="(key).toString()">
                      <template slot="title">
                        <div class="left-title">
                          <img :src="require('@/assets/images/'+item.icon+'.png')">
                          <span>{{ item.title }}</span>
                        </div>
                      </template>
                      <el-menu-item-group>
                        <div v-for="(twoItem,twoKey) in item.children" :key="twoKey">
                          <el-menu-item  v-if="authority.indexOf(item.children[twoKey].id)!=-1" :index="twoItem.path">
                            {{ twoItem.title }}
                          </el-menu-item>
                        </div>
                      </el-menu-item-group>
                    </el-submenu>
                  </template>

                </div>
              </template>
            </el-menu>
          </template>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import {jurisdictionAll} from '@/api/module/login';
import {mapState} from "vuex";

export default {
  components: {},
  props: [],
  computed: {
    ...mapState('common', {
      loginState: state => state
    }),
  },
  data() {
    return {
      authority:[],
      authMenuList:[],
    };
  },
  // [[1,12,23,24,13,25,26,29,30,31,42,14,33,34,35,36,37,38,46,49,15,39,40,41,16,43,50,51,17,44,18,45,47,48,52,19,20,21,22],[]]
  created() {
    this.authority = JSON.parse(localStorage.getItem("routerRole"));
    //获取菜单
    jurisdictionAll({}).then(menu=>{
      let menuList = menu.data
      let arrTest = [];
      for (let i = 0; i < menuList.length; i++) {
        let leftmenuitem = {};
        leftmenuitem.title = menuList[i].menu_name;
        leftmenuitem.id = menuList[i].id;
        leftmenuitem.icon = menuList[i].icon;
        if(menuList[i].children.length>0){
          //包含二级菜单的项
          let childenItem = [];
          for (let j=0;j<menuList[i].children.length;j++){
            let secondItem = {}
            secondItem.title = menuList[i].children[j].menu_name
            secondItem.id = menuList[i].children[j].id
            secondItem.path = menuList[i].children[j].remark_data
            secondItem.index = i + "-" + (parseInt(i)+1)
            childenItem.push(secondItem)
          }
          leftmenuitem.children = childenItem;
        }else {
          leftmenuitem.path = menuList[i].remark_data;
        }
        arrTest.push(leftmenuitem)
      }
      this.authMenuList = arrTest
      // console.log(menuList.length)
    })

  },
  mounted() {
  },
  methods: {
    close(val){
      console.log(val)
    },
    open(val){
      console.log(val)
    }

  }
};
</script>
<style>
.el-menu-item.is-active{
  background: rgba(0,0,0,0.49)!important;
}
</style>
<style lang="scss">
.layoutLeft-menu {
  min-height: calc(84.5vh) !important;
  //height: 1500px;
  //可能需要改的颜色 开始
  //第一级的下划线
  .el-submenu__title {
  }

  //左边的箭头
  .el-submenu__title i {
    color: #fff;
    font-size: 18px;
  }

  //选择状态
  .is-active {
    font-weight: bold;
  }

  //可能需要改的颜色 结束

  .el-menu-item-group__title {
    padding: 0 !important;
  }

  //
  .el-menu-item, .el-submenu__title {
    height: 42px !important;
    line-height: 42px !important;
    font-size: 14px;
    @include flex();
  }

  //二级离左边的距离
  .el-menu-item-group .el-menu-item {
    padding-left: 52px !important;
  }

  //二级下划线隐藏
  .el-menu--inline .el-submenu__title {
    padding-left: 52px !important;
    border-bottom: 0 !important;
  }


  .left-title img {
    margin-right: 15px;

  }

}
</style>
<style lang="scss" scoped>
.layoutLeft-menu {
  &:not(.el-menu--collapse) {
    width: 200px;
  }
}

</style>