<template>
  <div>
    <!-- 表头字体颜色 :header-cell-style="{
     'color': '#18a78a'}"-->
    <el-table
        ref="table"
        :data="tableData"
        row-key="id"
        border
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        lazy
        :load="load"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :show-header="tableProp.showheader"
        @selection-change="handleSelectionChange"
        v-loading="$store.state.loading"
        @sort-change="sortChange"
    >
      <!--序号-->
      <el-table-column
          v-if="tableProp.index"
          width="55"
          label="序号"
          type="index"
          align="center"
      >
      </el-table-column>
      <!--多选框-->
      <el-table-column
          v-if="tableProp.selection"
          width="55"
          type="selection"
          align="center"
      >
      </el-table-column>
      <!--循环出来的字段-->
      <template v-for="(item,key) in tableProp.table">
<!--        带升序降序功能-->
        <el-table-column
            :key="key"
            :label="item.title"
            :width="item.isWidth?item.width:'auto'"
            :min-width="!item.isWidth?item.width:'auto'"
            :align="item.align"
            :header-align="item.headerAlign"
            :show-overflow-tooltip="!item.tooltip"
            v-if="item.isShow==true"
            :sortable="item.field"
            :sort-orders="['descending','ascending']"
            :label-class-name="item.labelClassName"
        >
          <template slot-scope="scope">
            <template v-if="item.type==0">
              {{ scope.row[item.field] }}
            </template>

            <!--图片-->
            <div v-if="item.type===1" class="table-img">
              <ul v-if="scope.row[item.field]!=null">
                <li v-for="(itemImg, keyImg) in scope.row[item.field].split(',')" :key="keyImg">
                  <img :src="itemImg" @click="tableImgClick(itemImg)">
                </li>
              </ul>
              <div v-else>

              </div>
            </div>

            <!--操作-->
            <div v-if="item.type==201" class="table-button">
              {{ item }}

            </div>

            <!--操作-->
            <div v-if="item.type==2" class="table-button">
              {{item}}
              <ul>
                <li v-for="(itemBut,keyBut) in item.tableOperate" :key="keyBut">
                  <!--权限id不是2000要去对比是否有权限  2000直接过不要对比-->
                  <template v-if="itemBut.id!=20000">
                    <template v-if="itemBut.status.split(',').indexOf('20000')===-1">
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="itemBut.status.split(',').indexOf(scope.row[tableProp.statusName].toString())!==-1 && $store.state.userType.indexOf(itemBut.id)!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>
                    <template v-else>
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="$store.state.routerRole.indexOf(itemBut.id)!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>
                    </template>
                  <template v-else>
                    <template v-if="itemBut.status.split(',').indexOf('20000')===-1">
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="itemBut.status.split(',').indexOf(scope.row[tableProp.statusName].toString())!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>
                    <template v-else>
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                      >
                        {{ itemBut.name }}
                    </el-button>
                    </template>
                  </template>
                </li>
              </ul>
            </div>
            <!--json-->
            <div v-if="item.type==3">
              <span>
                 {{ scope.row[item.field] == null ? '' : (scope.row[item.field][item.name] == null ? '' : scope.row[item.field][item.name]) }}
              </span>
            </div>
            <!--时间戳-->
            <div v-if="item.type==4">
              <span>
                 {{ scope.row[item.field] == null ? '' : Utils.timeDate(scope.row[item.field]) }}
              </span>
            </div>
            <!--时间戳 时分秒-->
            <div v-if="item.type==9">
              <span>
                 {{scope.row[item.field] == null? '' : Utils.timeDateSeconds(scope.row[item.field]) }}
              </span>
            </div>
            <!--输入框-->
            <div v-if="item.type==5">
              <el-input v-model="scope.row[item.field]"
                        :placeholder="'请输入'+item.title"
                        @change="$emit(item.field+'DialogChange',scope.row)">

              </el-input>
            </div>
            <!--联动下拉框 一级-->
            <div v-if="item.type==8">
              <el-select v-model="scope.row[item.field]" :placeholder="'请选择'+item.title"
                         @change="$emit(item.field+'DialogChange',scope.row)">
                <el-option
                    v-for="(itemOption,keyOption) in tableProp[item.field+'Prop']"
                    :key="keyOption"
                    :label="itemOption.label"
                    :value="itemOption.value">
                </el-option>
              </el-select>
            </div>
            <!--联动下拉框-->
            <div v-if="item.type==80">
              <el-select v-model="scope.row[item.field]" :placeholder="'请选择'+item.title"
                         :disabled="item.disabled"
                         @change="$emit(item.field+'DialogChange',scope.row)">
                <el-option
                    v-for="(itemOption,keyOption) in scope.row[item.field+'Prop']"
                    :key="keyOption"
                    :label="itemOption.label"
                    :value="itemOption.value">
                </el-option>
              </el-select>
            </div>
            <!--时间选择器-->
            <div v-if="item.type==7">
              <div>              {{scope.row[item.field]}}
              </div>
              <el-date-picker type="date" placeholder="请选择日期" v-model="scope.row[item.field]" value-format="timestamp"
                              @change="$emit(item.field+'Change')"
                              style="width: 100%;"></el-date-picker>
            </div>
            <!--操作关联角色与状态-->
            <div v-if="item.type == 10">
              <ul>
                <li v-for="(itemBut,keyBut) in item.tableOperate" :key="keyBut">
                  <!--{{item.tableOperate}}-->
                  <!--权限id不是2000要去对比是否有权限  2000直接过不要对比-->
                  <template v-if="itemBut.id!=20000">

                    <template v-if="itemBut.status.split(',').indexOf('20000')===-1">
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="itemBut.status.split(',').indexOf(scope.row[tableProp.statusName].toString())!==-1 && $store.state.routerRole.indexOf(itemBut.id)!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>

                    <template v-else>
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="$store.state.routerRole.indexOf(itemBut.id)!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>

                  </template>

                  <template v-else>
                    <template v-if="itemBut.status.split(',').indexOf('20000')===-1">
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="itemBut.status.split(',').indexOf(scope.row[tableProp.statusName].toString())!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>

                    <template v-else>
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>

                    <template>

                    </template>
                  </template>
                </li>
              </ul>
            </div>
            <!--带权限的操作按钮-->
            <div v-if="item.type===20" class="table-button">
              <ul>
                <li v-for="(itemBut,keyBut) in item.tableOperate" :key="keyBut">
                  <template>
                    <!--                    scope.row.audit_info.status==0 代表已经审核过，通过和未通过都将不再进行操作-->
                    <div  v-if="scope.row.audit_status==0">
                      <el-button
                          :type="itemBut.type"
                          v-if="scope.row.check!=0||itemBut.key=='detail'"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </div>

                    <div v-else>
                      <el-button
                          v-if="itemBut.key=='detail'"
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </div>

                  </template>
                </li>
              </ul>
            </div>
            <!--序号-->
            <div v-if="item.type===13">{{scope.$index+1}}</div>
          </template>
        </el-table-column>
<!--        不带排序功能-->
        <el-table-column
            :key="key"
            :label="item.title"
            :width="item.isWidth?item.width:'auto'"
            :min-width="!item.isWidth?item.width:'auto'"
            :align="item.align"
            :header-align="item.headerAlign"
            :show-overflow-tooltip="!item.tooltip"
            :label-class-name="item.labelClassName"
            v-else
        >
          <template slot-scope="scope">
            <template v-if="item.type==0">
              <el-input v-model="scope.row[item.field]" :placeholder="'请输入'+item.title" v-if="item.isInput == 1"></el-input>
              <template v-else>
                {{ scope.row[item.field] }}
              </template>
            </template>
            <!--图片-->
            <div v-if="item.type===1" class="table-img">
              <ul v-if="scope.row[item.field]!=null">
                <li v-for="(itemImg, keyImg) in scope.row[item.field].split(',')" :key="keyImg">
                  <img :src="itemImg" @click="tableImgClick(itemImg)">
                </li>
              </ul>
              <div v-else>

              </div>
            </div>
            <!--操作
             let create_audit_status = ["交易前","交易后","不成单"]
              let end_audit_status = ["待审核","审核通过","审核不通过"]
            -->
            <div v-if="item.type===201" class="table-button">
              <ul>
                <li v-for="(itemBut,keyBut) in item.tableOperate" :key="keyBut">

                  <template v-if="scope.row.create_audit_status==0&&scope.row.end_audit_status==2">
                    <el-button
                        :type="itemBut.type"
                        @click="$emit(itemBut.method,scope.row,scope.$index)"
                        v-if="itemBut.method=='detail'||itemBut.method=='edit'||itemBut.method=='deleteBussiness'"
                    >
                      {{ itemBut.name }}
                    </el-button>
                  </template>
                  <template v-else-if="scope.row.create_audit_status==0&&scope.row.end_audit_status==1">
                    <el-button
                        :type="itemBut.type"
                        @click="$emit(itemBut.method,scope.row,scope.$index)"
                        v-if="itemBut.method=='detail'||itemBut.method=='edit'||itemBut.method=='order'||itemBut.method=='bucheng'"
                    >
                      {{ itemBut.name }}
                    </el-button>
                  </template>


                  <template v-else-if="scope.row.create_audit_status==1&&scope.row.end_audit_status==2">
                    <el-button
                        :type="itemBut.type"
                        @click="$emit(itemBut.method,scope.row,scope.$index)"
                        v-if="itemBut.method=='detail'||itemBut.method=='order'||itemBut.method=='bucheng'"
                    >
                      {{ itemBut.name }}
                    </el-button>
                  </template>
                  <template v-else>
                    <el-button
                        :type="itemBut.type"
                        @click="$emit(itemBut.method,scope.row,scope.$index)"
                        v-if="itemBut.method=='detail'"
                    >
                      {{ itemBut.name }}
                    </el-button>
                  </template>

                </li>
              </ul>
            </div>


            <div v-if="item.type===2" class="table-button">
              <ul>
                <li v-for="(itemBut,keyBut) in item.tableOperate" :key="keyBut">
                  <!--权限id不是2000要去对比是否有权限  2000直接过不要对比-->
                  <template v-if="itemBut.id!=20000">

                    <template v-if="itemBut.status.split(',').indexOf('20000')===-1">
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="itemBut.status.split(',').indexOf(scope.row[tableProp.statusName].toString())!==-1 && $store.state.userType.indexOf(itemBut.id)!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>

                    <template v-else>
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="$store.state.routerRole.indexOf(itemBut.id)!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>

                  </template>
                  <template v-else>
                    <template v-if="itemBut.status.split(',').indexOf('20000')===-1">
                      <template v-if="itemBut.status == 'control'">
                        <el-button
                            :type="itemBut.type"
                            @click="$emit(itemBut.method,scope.row,scope.$index)"
                            v-if="itemBut.status.split(',').indexOf(scope.row[itemBut.method + 'Control'])!==-1"
                        >
                          {{ itemBut.name }}
                        </el-button>
                      </template>
                      <template v-else>
                        <el-button
                            :type="itemBut.type"
                            @click="$emit(itemBut.method,scope.row,scope.$index)"
                            v-if="itemBut.status.split(',').indexOf(scope.row[tableProp.statusName]+'')!==-1"
                        >
                          {{ itemBut.name }}
                        </el-button>
                      </template>
                    </template>
                    <template v-else>
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>
                  </template>
                </li>
              </ul>
            </div>

            <div v-if="item.type==21" class="table-button">
              <ul>
                <li v-for="(itemBut,keyBut) in item.tableOperate" :key="keyBut">
                  <!--权限id不是2000要去对比是否有权限  2000直接过不要对比-->
                  <template v-if="itemBut.id!=20000">

                    <template v-if="itemBut.status.split(',').indexOf('20000')===-1">
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="itemBut.status.split(',').indexOf(scope.row[tableProp.statusName].toString())!==-1 && $store.state.userType.indexOf(itemBut.id)!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>

                    <template v-else>
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="$store.state.routerRole.indexOf(itemBut.id)!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>

                  </template>
                  <template v-else>
                    <template v-if="itemBut.status.split(',').indexOf('20000')===-1">
                      <template v-if="itemBut.status == 'control'">
                        <el-button
                            :type="itemBut.type"
                            @click="$emit(itemBut.method,scope.row,scope.$index)"
                            v-if="itemBut.status.split(',').indexOf(scope.row[itemBut.method + 'Control'])!==-1"
                        >
                          {{ itemBut.name }}
                        </el-button>
                      </template>
                      <template v-else>
                        <el-button
                            :type="itemBut.type"
                            @click="$emit(itemBut.method,scope.row,scope.$index)"
                            v-if="itemBut.status.split(',').indexOf(scope.row[tableProp.statusName]+'')!==-1"
                        >
                          {{ itemBut.name }}
                        </el-button>
                      </template>
                    </template>
                    <template v-else>
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="(itemBut.method=='detileLeave')"
                      >
                        {{ itemBut.name }}
                      </el-button>
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="(itemBut.method=='passOrder')&&(scope.row.status!=8)"
                      >
                        {{ itemBut.name }}
                      </el-button>

                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="(itemBut.method=='done')&&(scope.row.show_money_over_btn)"
                      >
                        {{ itemBut.name }}


                      </el-button>
                    </template>
                  </template>
                </li>
              </ul>
            </div>

            <!--json-->
            <div v-if="item.type==3">
              <span>
                 {{ scope.row[item.field] == null ? '' : (scope.row[item.field][item.name] == null ? '' : scope.row[item.field][item.name]) }}
              </span>
            </div>
            <!--时间戳-->
            <div v-if="item.type==4">
              <span>
                 {{ scope.row[item.field] == null || scope.row[item.field] == 0 ? '' : Utils.timeDate(scope.row[item.field]) }}
              </span>
            </div>
            <!--时间戳 时分秒-->
            <div v-if="item.type==9">
              <span>
                 {{scope.row[item.field] == null? '' : Utils.timeDateSeconds(scope.row[item.field]) }}
              </span>
            </div>
            <!--输入框-->
            <div v-if="item.type==5">
              <el-input v-model="scope.row[item.field]" :placeholder="'请输入'+item.title" @change="$emit(item.field + 'Change', scope.row, scope.$index)"></el-input>
            </div>
            <!--联动下拉框 一级-->
            <div v-if="item.type==8">
              <el-select v-model="scope.row[item.field]" :placeholder="'请选择'+item.title"
                         @change="$emit(item.field+'DialogChange',scope.row)">
                <el-option
                    v-for="(itemOption,keyOption) in tableProp[item.field+'Prop']"
                    :key="keyOption"
                    :label="itemOption.label"
                    :value="itemOption.value">
                </el-option>
              </el-select>
            </div>
            <!--联动下拉框-->
            <div v-if="item.type==6">
              <el-select v-model="scope.row[item.field]" :placeholder="'请选择'+item.title"
                         @change="$emit(item.field+'DialogChange',scope.row, scope.$index)">
                <el-option
                    v-for="(itemOption,keyOption) in scope.row[item.field+'Prop']"
                    :key="keyOption"
                    :label="itemOption.label"
                    :value="itemOption.value">
                </el-option>
              </el-select>
            </div>
            <div v-if="item.type==60">
              <el-select v-model="scope.row[item.field]" :placeholder="'请选择'+item.title"
                         :disabled="item.disabled"
                         @change="$emit(item.field+'DialogChange',scope.row, scope.$index)">
                <el-option
                    v-for="(itemOption,keyOption) in scope.row[item.field+'Prop']"
                    :key="keyOption"
                    :label="itemOption.label"
                    :value="itemOption.value">
                </el-option>
              </el-select>
            </div>
            <!--时间选择器-->
            <div v-if="item.type==7">
<!--              {{scope.row[item.field]}}-->
<!--              {{item.field}}-->
              <el-date-picker type="date" placeholder="请选择日期" v-model="scope.row[item.field]" value-format="timestamp"
                              @change="$emit(item.field+'Change', scope.row, scope.$index)"
                              style="width: 100%;"></el-date-picker>
            </div>
            <!--操作关联角色与状态-->
            <div v-if="item.type == 10">
              <ul>
                <li v-for="(itemBut,keyBut) in item.tableOperate" :key="keyBut">
                  <!--{{item.tableOperate}}-->
                  <!--权限id不是2000要去对比是否有权限  2000直接过不要对比-->
                  <template v-if="itemBut.id!=20000">

                    <template v-if="itemBut.status.split(',').indexOf('20000')===-1">
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="itemBut.status.split(',').indexOf(scope.row[tableProp.statusName].toString())!==-1 && $store.state.routerRole.indexOf(itemBut.id)!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>

                    <template v-else>
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="$store.state.routerRole.indexOf(itemBut.id)!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>

                  </template>

                  <template v-else>
                    <template v-if="itemBut.status.split(',').indexOf('20000')===-1">
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="itemBut.status.split(',').indexOf(scope.row[tableProp.statusName].toString())!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>

                    <template v-else>
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>

                    <template>

                    </template>
                  </template>
                </li>
              </ul>
            </div>
            <!--单选框-->
            <div v-if="item.type==11">
              <el-radio-group v-model="scope.row[item.field]">
                <el-radio
                    v-for="(itemOption,keyOption) in tableProp[item.field+'Prop']"
                    :key="keyOption"
                    :label="itemOption.value">{{ itemOption.label }}</el-radio>
              </el-radio-group>
            </div>
            <!--点击弹窗-->
            <div v-if="item.type==12">
              <el-popover
                  placement="right"
                  width="200"
                  trigger="hover"
                  @show="$emit(item.method,scope.row,scope.$index)">
                <template v-for="(spitem, index) in scope.row[item.infolist]">
                  <div :key="index">{{spitem.name}} {{spitem.status}}</div>
                </template>
                <div slot="reference">{{ scope.row[item.field] }}</div>
              </el-popover>
            </div>
            <!--带权限的操作按钮-->
            <div v-if="item.type==20" class="table-button">
              <ul>
                <li v-for="(itemBut,keyBut) in item.tableOperate" :key="keyBut">
                  <template>
                    <!--                    scope.row.audit_info.status==0 代表已经审核过，通过和未通过都将不再进行操作-->
<!--                    <div  v-if="scope.row.audit_status==0">-->
                    <div  v-if=" scope.row.audit_info.status==0">
                      <el-button
                          :type="itemBut.type"
                          v-if="scope.row.check!=0||itemBut.key=='detail'"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </div>

                    <div v-else>
<!--                      <el-button-->
<!--                          v-if="itemBut.key=='detail'"-->
<!--                          :type="itemBut.type"-->
<!--                          @click="$emit(itemBut.method,scope.row,scope.$index)"-->
<!--                      >-->
<!--                        {{ itemBut.name }}-->
<!--                      </el-button>-->
                    </div>



                  </template>
                </li>
              </ul>
            </div>
            <!--序号-->
            <div v-if="item.type==13">{{scope.$index+1}}</div>

          </template>
        </el-table-column>
      </template>
    </el-table>

    <!--分页-->
    <div class="pagination" v-if="tableProp.isPage">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tableProp.page.current_page"
          :page-size="tableProp.page.size"
          :page-sizes="[10, 20, 30, 40,50]"
          :layout="tableProp.page.type == 2?'total, sizes, prev, pager, next': 'total, prev, pager, next'"
          :total="tableProp.page.total">
      </el-pagination>
    </div>

    <!--点击图片 dialog-->
    <el-dialog
        :append-to-body="true"
        ref="tableImg"
        title="图片"
        :visible.sync="tableImgDialogVisible"
        width="600px"
        custom-class="table-img-dialog"
    >
      <img :src="tableImgDialogUrl">
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['tableData', 'tableProp', 'spjl'],
  data() {
    return {
      //图片 Dialog 显示隐藏
      tableImgDialogVisible: false,
      tableImgDialogUrl: '',
    };
  },
  mounted() {
  },
  created() {
    // console.log('asdasrqwer')
  },
  methods: {
    sortChange(column){
      this.$emit("sortChangeData",column.column.sortable,column.order)
    },


    //分页
    handleCurrentChange(val) {
      this.$emit(this.tableProp.page.method, val)
    },
    handleSizeChange(val) {
      this.$emit(this.tableProp.page.method2, val)
    },
    load(tree, treeNode, resolve) {
      this.$emit(this.tableProp.load, tree, treeNode, resolve)
    },
    //表格 多选后的值
    handleSelectionChange(selection) {
      let tableDataROW = [];
      selection.forEach((value) => {
        this.tableData.forEach((val) => {
          if (value[this.tableProp.selectionVal] == val[this.tableProp.selectionVal]) {
            tableDataROW.push(value[this.tableProp.selectionVal]);
          }
        });
      });
      //把多选完后的数组保存起来
      this.tableProp.selectionId = [];
      this.tableProp.selectionId = tableDataROW;
      console.log(this.tableProp.selectionId)
    },
    //图片 点击
    tableImgClick(url) {
      this.tableImgDialogVisible = true;
      this.tableImgDialogUrl = url;
    },
    //表格 第几行显示什么颜色
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      }
      return '';
    },

  //  点击变输入框
    changeInput(value){
      console.log(value)
    }
  },
};
</script>

<style lang="scss" scoped>
//图片
.table-img {
  ul {
    @include flex(wrap);
  }

  img {
    height: 60px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
  }
}

//图片 dialog
.table-img-dialog {
  img {
    width: 100%;
  }
}

//操作
.table-button {
  ul {
    @include flex(wrap, center);

    li {
      .el-button--small {
        margin: 5px;
      }
    }
  }
}

//分页
//分页
.pagination {
  position: fixed;
  bottom: 0;
  background: #fff;
  width: calc(100% - 248px);
  margin-left: -12px;
  padding: 12px;
  @include flex(nowrap, flex-end);
  z-index: 999;
  border-radius: 10px;
}

</style>
<style lang="scss">
.sss{
.el-tooltip{
  display: flex !important;
  justify-content: center !important;
}
.el-table__row--level-1 td:nth-of-type(2)>div{
  margin-left: 40px !important;
}
}

</style>
