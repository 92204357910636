<template>
  <el-dialog
      :append-to-body="true"
      :title="addDialogData.title"
      :visible.sync="addDialogData.isDialog"
      :top="addDialogData.top"
      :width="addDialogData.width"
  >
    <div class="common-add-dialog">
      <ul>
        <li v-for="(item,key) in addDialogData.addDialogProp" :key="key" v-show="item.show">
          <span :style="{'width':addDialogData.labelWidth}">{{ item.label }}</span>
          <!--显示-->
          <template v-if="item.type===1">
            {{ addDialogData.addDialogShowData[item.field] }}
          </template>
          <!--输入框-->
          <template v-else-if="item.type===2">
            <el-input v-model="addDialogData.addDialogShowData[item.field]" :placeholder="'请输入'+item.label"></el-input>
          </template>
          <!--下拉框-->
          <template v-else-if="item.type===3">
            <el-select v-model="addDialogData.addDialogShowData[item.field]" :placeholder="'请选择'+item.label">
              <el-option
                  v-for="(itemOption,keyOption) in addDialogData[item.field+'Prop']"
                  :key="keyOption"
                  :label="itemOption.label"
                  :value="itemOption.value">
              </el-option>
            </el-select>
          </template>
          <!--单选框-->
          <template v-else-if="item.type===4">
            <el-radio-group v-model="addDialogData.addDialogShowData[item.field]">
              <el-radio
                  v-for="(itemRadio,keyRadio) in addDialogData[item.field+'Prop']"
                  :key="keyRadio"
                  :label="itemRadio.value"
              >
                {{ itemRadio.label }}
              </el-radio>
            </el-radio-group>
          </template>
          <!--多选框-->
          <template v-else-if="item.type===5">
            <el-checkbox-group v-model="addDialogData.addDialogShowData[item.field]">
              <el-checkbox
                  v-for="(itemCheck,keyCheck) in addDialogData[item.field+'Prop']"
                  :key="keyCheck"
                  :label="itemCheck.value"
              >
                {{ itemCheck.label }}
              </el-checkbox>
            </el-checkbox-group>
          </template>
          <!--文本域-->
          <template v-else-if="item.type===6">
            <el-input
                type="textarea"
                :autosize="item.minRow"
                :placeholder="'请输入'+item.label"
                v-model="addDialogData.addDialogShowData[item.field]"
            >
            </el-input>
          </template>
          <!--上传图片-->
          <template v-else-if="item.type===7">
            <common-upload-img :uploadImg="addDialogData.addDialogShowData[item.field]"/>
          </template>
          <!--上传文件-->
          <template v-else-if="item.type===8">
            <common-upload-file :uploadFileData="addDialogData.addDialogShowData[item.field]"/>
          </template>
          <!--下拉框有方法-->
          <template v-else-if="item.type===9">
            <el-select ref="select" v-model="addDialogData.addDialogShowData[item.field]" :placeholder="'请选择'+item.label" @change="$emit(item.field+'DialogChange',addDialogData.addDialogShowData[item.field])">
              <el-option
                  v-for="(itemOption,keyOption) in addDialogData[item.field+'Prop']"
                  :key="keyOption"
                  :label="itemOption.label"
                  :value="itemOption.value">
              </el-option>
            </el-select>
          </template>
          <!--日期选择器-->
          <template v-else-if="item.type===10">
            <el-date-picker type="date" placeholder="请选择日期" v-model="addDialogData.addDialogShowData[item.field]" value-format="timestamp" @change="$emit(item.field+'Change')"
                            style="width: 100%;"></el-date-picker>
          </template>
          <!--不可输入输入框-->
          <template v-else-if="item.type===11">
            <el-input v-model="addDialogData.addDialogShowData[item.field]" :placeholder="'请输入'+item.label" disabled></el-input>
          </template>
          <!--日期时间选择器-->
          <template v-else-if="item.type===12">
            <el-date-picker type="datetime" placeholder="请选择日期" v-model="addDialogData.addDialogShowData[item.field]" value-format="timestamp" @change="$emit(item.field+'Change')"
                            style="width: 100%;"></el-date-picker>
          </template>
          <!--树形结构-->
          <template v-else-if="item.type===13">
            <common-tree :treeData = "addDialogData.addDialogShowData[item.field]"></common-tree>
          </template>
        </li>
      </ul>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="addDialogData.isDialog=false">取 消</el-button>
      <el-button type="primary" @click="$emit(addDialogData.subMethod)">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {

  props: ['addDialogData'],
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
    // 上传图片
  /*  for (let i = 0; i < this.addDialogData.addDialogProp.length; i++) {
      if(this.addDialogData.addDialogProp[i].type===7){
        this.addDialogData.addDialogProp[i].uploadImg.img=this.addDialogData.addDialogShowData[this.addDialogData.addDialogProp[i].field];
       continue
      }
    }*/
  },
  methods: {

  },
};
</script>
<style lang="scss">
.common-add-dialog {
  li {
    //文本域
    .el-textarea__inner {
      padding: 5px 8px;
    }
  }
}

</style>
<style lang="scss" scoped>
.common-add-dialog {
  li:nth-last-of-type(1){
    margin-bottom: 0;
    align-items: baseline;
  }
  li {
    margin-bottom: 15px;

    span {
      width: 120px;
      text-align: right;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    @include flex();
    //下拉框
    .el-select {
      flex: 1;
    }
    //单选
    .el-radio{
      margin: 3px 30px 3px 0;
    }
    //多选
    .el-checkbox{
      margin: 3px 30px 3px 0;
    }

  }
}
</style>
