<template>
  <el-dialog
      :append-to-body="true"
      :title="addDialogData.title"
      :visible.sync="addDialogData.isDialog"
      :top="addDialogData.top"
      :width="addDialogData.width"
  >
    <div class="common-add-dialog">
      <ul>
        <li v-for="(item,key) in addDialogData.addDialogProp" :key="key" v-show="item.show">
          <span :style="{'width':addDialogData.labelWidth}">{{ item.label }}</span>
          <!--显示-->
          <template v-if="item.type===1">
            {{ addDialogData.addDialogShowData[item.field] }}
          </template>
          <!--输入框-->
          <template v-else-if="item.type===2">
            <el-input v-model="addDialogData.addDialogShowData[item.field]" :placeholder="'请输入'+item.label"></el-input>
          </template>
          <!--下拉框-->
          <template v-else-if="item.type===3">
            {{ addDialogData.addDialogShowData[item.field] }}
            <el-select v-model="addDialogData.addDialogShowData[item.field]" :placeholder="'请选择'+item.label">
              <el-option
                  v-for="(itemOption,keyOption) in addDialogData[item.field+'Prop']"
                  :key="keyOption"
                  :label="itemOption.label"
                  :value="itemOption.value">
              </el-option>
            </el-select>
          </template>
          <!--单选框-->
          <template v-else-if="item.type===4">
            <el-radio-group v-model="addDialogData.addDialogShowData[item.field]">
              <el-radio
                  v-for="(itemRadio,keyRadio) in addDialogData[item.field+'Prop']"
                  :key="keyRadio"
                  :label="itemRadio.value"
              >
                {{ itemRadio.label }}
              </el-radio>
            </el-radio-group>
          </template>
          <!--多选框-->
          <template v-else-if="item.type===5">
            <el-checkbox-group v-model="addDialogData.addDialogShowData[item.field]">
              <el-checkbox
                  v-for="(itemCheck,keyCheck) in addDialogData[item.field+'Prop']"
                  :key="keyCheck"
                  :label="itemCheck.value"
              >
                {{ itemCheck.label }}
              </el-checkbox>
            </el-checkbox-group>
          </template>
          <!--文本域-->
          <template v-else-if="item.type===6">
            <el-input
                type="textarea"
                :autosize="item.minRow"
                :placeholder="'请输入'+item.label"
                v-model="addDialogData.addDialogShowData[item.field]"
            >
            </el-input>
          </template>
          <!--上传图片-->
          <template v-else-if="item.type===7">
            <common-upload-img :uploadImg="addDialogData.addDialogShowData[item.field]"/>
          </template>
          <!--上传文件-->
          <template v-else-if="item.type===8">
            <common-upload-file :uploadFileData="addDialogData.addDialogShowData[item.field]"/>
          </template>
          <!--下拉框有方法-->
          <template v-else-if="item.type===9">
            <el-select ref="select" v-model="addDialogData.addDialogShowData[item.field]"
                       :placeholder="'请选择'+item.label"
                       @change="$emit(item.field+'DialogChange',addDialogData.addDialogShowData[item.field])">
              <el-option
                  v-for="(itemOption,keyOption) in addDialogData[item.field+'Prop']"
                  :key="keyOption"
                  :label="itemOption.label"
                  :value="itemOption.value">
              </el-option>
            </el-select>
          </template>
          <!--日期选择器-->
          <template v-else-if="item.type===10">
            <el-date-picker type="date" placeholder="请选择日期" v-model="addDialogData.addDialogShowData[item.field]"
                            value-format="timestamp" @change="$emit(item.field+'Change')"
                            style="width: 100%;"></el-date-picker>
          </template>
          <!--不可输入输入框-->
          <template v-else-if="item.type===11">
            <el-input v-model="addDialogData.addDialogShowData[item.field]" :placeholder="'请输入'+item.label"
                      disabled></el-input>
          </template>
          <!--日期时间选择器-->
          <template v-else-if="item.type===12">
            <el-date-picker type="datetime" placeholder="请选择日期" v-model="addDialogData.addDialogShowData[item.field]"
                            value-format="timestamp" @change="$emit(item.field+'Change')"
                            style="width: 100%;"></el-date-picker>
          </template>
          <!--树形结构-->
          <template v-else-if="item.type===13">

            <common-tree :treeData="addDialogData.addDialogShowData[item.field]"></common-tree>
          </template>

          <template v-else-if="item.type===14">
            <div>
                <el-button type="primary" @click="addMember">添加成员</el-button>
                <common-table
                    :tableData="addDialogData.addDialogShowData[item.field]"
                    :tableProp="tableProp"
                    @department_nameDialogChange = "getPost"
                    @post_nameDialogChange = "getUser"
                    @deleteMember = "deleteMember"/>
            </div>

          </template>

          <template v-else-if="item.type===15">
            <div>
              <el-button type="primary" @click="addBoss">添加审批人</el-button>


            </div>

          </template>


        </li>
      </ul>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="addDialogData.isDialog=false">取 消</el-button>
      <el-button type="primary" @click="$emit(addDialogData.subMethod)">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>

import {getUserFromStation, twoDepartment} from "../api/module/user/user_func";
export default {

  props: ['addDialogData'],
  data() {
    return {
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '部门',
          field: 'department_name',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 6,
        }, {
          title: '岗位',
          field: 'post_name',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 6,
        }, {
          title: '姓名',
          field: 'user_id',
          isWidth: false,
          width: '150px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 6,
        }, {
          title: '计划开始时间',
          field: 'start_time',
          isWidth: false,
          width: '150px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 7,
        }, {
          title: '计划结束时间',
          field: 'end_time',
          isWidth: false,
          width: '150px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 7,
        }, {
          title: '计划参与度(%)',
          field: 'join_rate',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '删除',
            type: 'danger',
            method: 'deleteTemplate',
            status: '20000',
            id: 20000,
          }],

        }],
        department_nameProp: [
          {value: 1, label: '我是选项1'},
          {value: 2, label: '我是选项2'}
        ],
        post_nameProp: [],
        user_idProp: [],
      },
      subData: [],
    };
  },
  created() {
  },
  mounted() {

  },
  methods: {
    deleteMember(row, index) {
      this.addDialogData.addDialogShowData.member_json.splice(index, 1)

    },
    getUser(val) {
      let data = {
        department_id: val
      }
      getUserFromStation(data).then(res => {
        let list = res.data;
        this.tableProp.user_idProp = list.map(item => ({
          label: item.name,
          value: item.user_id
        }));
      })
    },

    getPost(val) {
      val = JSON.parse(val)
      let data = {
        pid: val.id
      }
      twoDepartment(data).then(res => {
        let list = res.data;
        for (let i = 0; i < list.length; i++) {
          list[i].label = list[i].department_name;
          list[i].value = list[i].id;
        }
        this.tableProp.post_nameProp = list
      })
    },
    // 添加成员 按钮点击
    addMember() {

      console.log(this.addDialogData)
      let item = {
        department_name: '',
        post_name: '',
        staff_name: '',
        start_time: '',
        end_time: '',
        participation: '',
      }
      // this.tableProp.department_nameProp = this.addDialogData.department_nameProp
      this.addDialogData.addDialogShowData.member_json.push(item)
    },
    //点击添加审批人
    addBoss() {

    }
  },
};
</script>
<style lang="scss">
.common-add-dialog {
  li {
    //文本域
    .el-textarea__inner {
      padding: 5px 8px;
    }
  }
}

</style>
<style lang="scss" scoped>
.common-add-dialog {
  li:nth-last-of-type(1) {
    margin-bottom: 0;
    align-items: baseline;
  }

  li {
    margin-bottom: 15px;

    span {
      width: 120px;
      text-align: right;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    @include flex();
    //下拉框
    .el-select {
      flex: 1;
    }

    //单选
    .el-radio {
      margin: 3px 30px 3px 0;
    }

    //多选
    .el-checkbox {
      margin: 3px 30px 3px 0;
    }

  }
}
</style>
