import router from '@/router'
import store from '@/store'
//权限控制
// onlyOne 只有一级菜单 1
// towTitle 有三级列表的二级列表标题 1
/* authority
    0 不写到动态路由，不要导航条上，如登录页面
    1 2 3 ...页面的权限登录页面 window.localStorage.setItem() 存起来的权限
    100 所有权限都要显示
 */
// id：20000都有权限
// 二级菜单里面写  meta: {authority: [0],isShow:true},  为true 不显示到导航里面
export default {
    saveList() {
        //当前账号权限
        let currentPermissions = Number(window.localStorage.getItem('currentPermissions'));
        //路由数组
        const routerArry = router.options.routes;

        //路由数组长度
        const routerLeng = routerArry.length;
        //新的路由数组
        let newRouterArru = [
// {
//     title:'一级菜单名称',
//     icon:'一级菜单图标',
//     path:'没有子菜单的时候需要使用',
//     children:[{
//         title:'二级菜单名称',
//         path:'路由地址',
//         threeMenu:1  等于1时候说明有三级菜单，是单前三级菜单的2级菜单标题
//         children:[{
//             title:'三级菜单名称',
//             path:'路由地址',
//         }]
//     }],
// }
        ];

        let menuRouter = {};//当前i路由的处理完后存放的数据

        for (let i = 0; i < routerLeng;
             i++
        ) {

            let oneArry = routerArry[i];//当前i路由里的数据
            //一级是否需要写入路由数组 0直接跳出
            if (oneArry.meta.authority[0] === 0) {
                continue
            }

            //一级菜单判断是否有权限
            if (oneArry.meta.authority.indexOf(100) == -1) {
                if (oneArry.meta.authority.indexOf(currentPermissions) == -1) {
                    continue
                }
            }

            //一级菜单是否没有子菜单
            if (oneArry.meta.onlyOne == 1) {
                menuRouter = {
                    title: oneArry.meta.title,
                    icon: oneArry.meta.icon,
                    path: oneArry.path,
                    id: oneArry.meta.id
                }
                newRouterArru.push(menuRouter)
                continue
            }
            menuRouter = {
                title: oneArry.meta.title,
                icon: oneArry.meta.icon,
                id: oneArry.meta.id,
                children: []
            }
            // isShow:oneArry.isShow,
            //开始操作二级菜单
            let oneArryChildren = oneArry.children;//当前i路由里的子路由
            for (let y = 0; y < oneArryChildren.length; y++) {

                //二级菜单判断是否有权限
                if (oneArryChildren[y].meta.authority.indexOf(100) == -1) {
                    if (oneArryChildren[y].meta.authority.indexOf(currentPermissions) == -1) {
                        continue
                    }
                }
                //二级菜单里面的菜单不显示到左边导航条
                if(oneArryChildren[y].meta.isShow){
                    continue
                }
                //是否有三级级菜单 不是直接输出
                if (oneArryChildren[y].meta.towTitle != 1) {

                    menuRouter.children.push({
                        title: oneArryChildren[y].meta.title,
                        path: oneArry.path + '/' + oneArryChildren[y].path,
                        id: oneArryChildren[y].meta.id
                    })
                    continue
                }
                //否则分解三级菜单
                menuRouter.children.push({
                    title: oneArryChildren[y].meta.title,
                    path: oneArry.path + '/' + oneArryChildren[y].path,
                    threeMenu: 1,
                    id: oneArryChildren[y].meta.id,
                    children: []
                })
                let twoArryChildren = oneArryChildren[y].children;//当前y路由里的子路由
                for (let z = 0; z < twoArryChildren.length; z++) {
                    //三级菜单判断是否有权限
                    if (twoArryChildren[z].meta.authority.indexOf(100) == -1) {
                        if (twoArryChildren[z].meta.authority.indexOf(currentPermissions) == -1) {
                            continue
                        }
                    }

                    //存入
                    menuRouter.children[0].children.push({
                        title: twoArryChildren[z].meta.title,
                        path: oneArry.path + '/' + oneArryChildren[y].path + '/' + twoArryChildren[z].path,
                        id: twoArryChildren[z].meta.id,
                    })
                }
            }
            newRouterArru.push(menuRouter)
        }
        //存入vuex 给layou循环解析出来
        store.state.newRouterArru = newRouterArru;
        //权限路由
        let menu_ids = JSON.parse(localStorage.getItem('routerRole'));
        store.state.routerRole = menu_ids;
        store.state.userType = localStorage.getItem('userType');
    }
}
