import http from '../http';

export function getData (params) {
    return http({
        url: "/api/common/indexData",
        method: "get",
        params:params
    })
}
//公告列表
export function getGg (params) {
    return http({
        url: "/api/announcement/announcement",
        method: "get",
        params:params
    })
}
//公告详情
export function ggDetail (params) {
    return http({
        url: "/api/announcement/announcementDetail",
        method: "get",
        params:params
    })
}
//未读消息数量
export function getMsgCount (params) {
    return http({
        url: "/api/announcement/messageCount",
        method: "get",
        params:params
    })
}

//消息分页
export function getMsg (params) {
    return http({
        url: "/api/announcement/messagePage",
        method: "get",
        params:params
    })
}
//消息详情
export function getMsgDetail (params) {
    return http({
        url: "/api/announcement/message",
        method: "get",
        params:params
    })
}
//消息 删除
export function deleteMsg (params) {
    return http({
        url: "/api/announcement/message",
        method: "delete",
        params:params
    })
}
//消息 删除
export function messageDeleteBatch (params) {
    return http({
        url: "/api/announcement/messageDeleteBatch",
        method: "delete",
        params:params
    })
}

export function deleteReimburse (params) {
    return http({
        url: "/api/project/deleteProject",
        method: "delete",
        params:params
    })
}

