<template>
  <div class="upload-file">
    <el-upload
        :action="uploadFileUrl"
        :show-file-list="false"
        list-type="text"
        :auto-upload="true"
        :before-upload="uploadFileUpload"
        :on-error="uploadFileError"
        :on-success="uploadFileSuccess"
        :multiple="false"
        name="attachment"
        :data="uploadFileField"
    >
    </el-upload>
    <div class="upload-file-box">
      <ul>
        <li
            v-for="(item,key) in uploadFileData"
            :key="key"
            @mouseenter="liHoverTrue" @mouseleave="liHoverFalse"
        >
          <i class="el-icon-document"></i>
          <span>{{ item.file_name }}</span>
          <i class="el-icon-check"></i>
          <i class="el-icon-download" @click="uploadFileDownload(item.url)"></i>
<!--          <i class="el-icon-close" @click="uploadFileDel(key)"></i>-->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['uploadFileData'],
  data() {
    return {
      //附件
      uploadFileUrl: process.env.VUE_APP_URL + '/api/common/uploadFile',
      uploadFileField: {remarks: 1}, //上传添加的字段
      uploadFileName: '', //上传文件成功后文件的名称
      isHover: false
    };
  },
  mounted() {
  },
  methods: {
    //列表 li鼠标移入
    liHoverTrue(event){
      event.currentTarget.className="li-hover"
    },
    //列表 li鼠标移除
    liHoverFalse(event){
      event.currentTarget.className=""
    },
    //附件下载
    uploadFileDownload(url) {
      window.location = url
    },
    //附件 移除文件时的钩子
    uploadFileDel(key) {
      this.$confirm('是否需要删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.uploadFileData.splice(key, 1);
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });


    },
    //附件 上传失败的钩子
    uploadFileError() {
      this.$message.error('附件上传失败');
    },
    //附件 上传成功钩子
    uploadFileSuccess(file) {
      let data = {
        name: this.uploadFileName,
        url: file.data
      }
      this.uploadFileData.push(data.url);
      this.$message.warning('附件上传成功，还需要提交');
    },
    //附件 上传文件之前的钩子
    uploadFileUpload(file) {
      this.uploadFileName = file.name;//名字存起来，传给后台要用
      // const isText = (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.ms-powerpoint' || file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation');
      // if (!isText) {
      //   this.$message.error('上传附件格式错误!');
      // }
      // return isText;
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-file {
  @include flex(wrap,flex-start,flex-start);

  &-box {
    ul {
      @include flex(wrap);

      .li-hover {
        /*background: $bg-basic;*/

        i {
          &:nth-of-type(2) {
            display: none;
          }

          &:nth-of-type(3) {
            display: inline-block;

          }

          &:nth-of-type(4) {

            display: inline-block;
          }
        }
      }

      li {
        padding: 5px;
        border-radius: 5px;
        font-size: 14px;
        @include flex();
        cursor: pointer;

        span {
          font-size: 16px;
          margin-right: 8px;
        }

        i {
          font-size: 13px;
          background: #303133;
          border-radius: 50%;
          padding: 3px;
          margin: 0 5px;
          color: #fff;

          &:nth-of-type(1) {
            background: none;
            font-size: 18px;
            /*color: $color-basic;*/
          }

          &:nth-of-type(2) {
            background: $color-success;
          }

          &:nth-of-type(3) {
            background: $color-info;
            display: none;
          }

          &:nth-of-type(4) {
            background: $color-danger;
            display: none;
          }
        }
      }
    }
  }
}
</style>
