
import http from '../../http';
export function getDetail (params) {
    return http({//获取用户列表
        url: "/api/user/userDetailUserInfo",
        method: "get",
        params:params
    })
}
//薪资变更记录
export function getSalaryLog(params) {
    return http({//获取用户列表
        url: "/api/ltd/userSalaryLogUserPage",
        method: "get",
        params:params
    })
}
//我的转正记录
export function getRegularRecordList(params) {
    return http({//获取用户列表
        url: "/api/regularrecord/regularrecordlist",
        method: "get",
        params:params
    })
}
//我的工资单
export function mySalaryPage(params) {
    return http({
        url: "/api/reportStats/salaryStatsUserPage",
        method: "get",
        params:params
    })
}

//我的请假记录
export function myTakeOffPage(params) {
    return http({//获取用户列表
        url: "/api/reportStats/myTakeOffPage",
        method: "get",
        params:params
    })
}
//个人信息
export function getMessageUserPage (params) {
    return http({//获取用户列表
        url: "/api/ltd/messageUserPage",
        method: "get",
        params:params
    })
}
//我的分红列表
// export function getMyRedPage (params) {
//     return http({//获取用户列表
//         url: "/api/project/getMyRedPage",
//         method: "get",
//         params:params
//     })
// }

export function getMyRedPage (params) {
    return http({//获取用户列表
        url: "/api/user/myRed",
        method: "get",
        params:params
    })
}




//我的成长值
export function getMyGrowPage (params) {
    return http({//获取用户列表
        url: "/api/project/getMyGrowPage",
        method: "get",
        params:params
    })
}
//我的报销列表
export function getMyReimbursePage (params) {
    return http({//获取用户列表
        url: "/api/reportStats/myReimbursePage",
        method: "get",
        params:params
    })
}
//提交报销（普通）
export function putReimburse1 (data) {
    return http({
        url: "api/reportStats/reimburse/1",
        method: "put",
        data:data
    })
}
//提交报销（差旅）
export function putReimburse2 (data) {
    return http({
        url: "api/reportStats/reimburse/2",
        method: "put",
        data:data
    })
}

export function editUserInfo (data) {
    return http({
        url: "/api/ltd/userInfoUserCreateAndUpdate",
        method: "put",
        data:data
    })
}
export function editUserEdu (data) {
    return http({
        url: "/api/ltd/userEducationUserCreateAndUpdate",
        method: "put",
        data:data
    })
}
//工资单确认
export function confSalaryStatsUser (data) {
    return http({
        url: "/api/reportStats/salaryStatsUser",
        method: "post",
        data:data
    })
}

export function editUserWork (data) {
    return http({
        url: "/api/ltd/userWorkUserCreateAndUpdate",
        method: "put",
        data:data
    })
}

//转正申请
export function applyRegular (data) {
    return http({
        url: "/api/apply/apply",
        method: "post",
        data:data
    })
}

//我的任务列表
export function getMyTaskPage (params) {
    return http({//获取用户列表
        url: "/api/project/myTaskPage",
        method: "get",
        params:params
    })
}

//申请离职按钮点击
export function clickLeave (params) {
    return http({//获取用户列表
        url: "/api/user/userLeaveIndex",
        method: "get",
        params:params
    })
}
//申请离职提交
export function subLeave (data) {
    return http({//获取用户列表
        url: "/api/user/userLeave",
        method: "post",
        data:data
    })
}