<template>
  <div class="content-tile">
    <div class="su-title">
      <i class="el-icon-document-copy"></i>
      <span>{{ titleProp.name }}</span>
    </div>
    <div v-if="titleProp.butOperate.length!=0">
      <template v-for="(item,key) in titleProp.butOperate">
        <!--横等于20000显示-->
        <el-button
            :type="item.type"
            :key="key"
            @click="$emit(item.method)"
            v-if="item.id==2000"
        >
          {{ item.name }}
        </el-button>
        <!--判断权限-->
        <el-button
            :type="item.type"
            :key="key"
            @click="$emit(item.method)"
            v-else-if="$store.state.routerRole.indexOf(item.id)"
        >
          {{ item.name }}
        </el-button>
      </template>

    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['titleProp'],
  data() {
    return {};
  },
  mounted() {
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.content-tile {
  @include flex(nowrap, space-between, center);
  background: $title-bg;
  border: 1px solid $title-bg;
  padding: 8px;
  margin-bottom: 12px;

  .su-title {
    font-weight: bold;
    @include flex();

    i {
      font-size: 16px;
      color: $title-icon;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}
</style>
