//调用 $store.state.test
const state = {
    test: 1,
    newRouterArru:[],//路由数组 左侧导航使用
    routerRole:[160], // 当前角色权限id 如[1,2,3]  超级管理员是 routerRole:['*']
    userType: '',
    rules: {
        username: [
            { required: true, message: '请选择公司成员', trigger: 'change' }
        ],
        start_time: [
            { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
        ],
        end_time: [
            { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
        ],
        desc: [
            { required: true, message: '请填写请假描述', trigger: 'blur' }
        ],
        project_title: [
            {required: true, message: '必填', trigger: 'blur'},
        ],
    },
    loading:false,
    notice_num: 0
}
export default state
