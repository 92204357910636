<template>
  <div class="upload-img">
    <!--
    action 上传接口地址
    show-file-list 是否显示已上传文件列表 缩略图
    list-type 文件列表的类型：文件上传后怎么布局
    on-preview 点击文件列表中已上传的文件时的钩子 uploadImgPreview
    :before-remove 删除之前提示
    on-remove 文件列表移除文件时的钩子
    auto-upload 是否在选取文件后立即进行上传
    before-upload 上传文件之前的钩子  判断是否大于小于等
    on-error  上传失败
    on-success 上传成功
    multiple 是否支持多选文件
    limit 最多可以传多少个
    on-exceed 文件超出个数限制时的钩子
    name 上传的文件字段名
    data 上传时附带的额外参数
    -->
    <template v-if="uploadImg.isAll">
      <el-upload
          :action="uploadImgUrl"
          :show-file-list="false"
          list-type="picture-card"
          :auto-upload="true"
          :before-upload="uploadImgUpload"
          :on-error="uploadImgError"
          :on-success="uploadImgSuccess"
          :multiple="false"
          name="attachment"
          :data="uploadImgField"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div class="Upload-img-show">
        <ul>
          <li v-for="(item,key) in uploadImg.img" :key="key">
            <img :src="item" @click="uploadImgClick(item)">
            <i class="el-icon-close" @click="uploadImgRemove(key)"></i>
          </li>
        </ul>
      </div>
    </template>
    <template v-else>
      <el-upload
          :action="uploadImgUrl"
          :show-file-list="false"
          list-type="picture-card"
          :auto-upload="true"
          :before-upload="uploadImgUpload"
          :on-error="uploadImgError"
          :on-success="uploadImgSuccess"
          :multiple="false"
          name="attachment"
          :data="uploadImgField"
      >
        <img
            v-if="uploadImg.img.length !=0"
            :src="uploadImg.img"
            style="width: 145px;height: 145px">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </template>
    <!--点击图片 dialog-->
    <el-dialog
        :append-to-body="true"
        ref="tableImg"
        title="图片展示"
        :visible.sync="uploadImgVisible"
        width="600px"
        top="20px"
        custom-class="upload-img-dialog"
        v-if="uploadImg.isAll"
    >
      <img :src="uploadImgDialogUrl">
    </el-dialog>

  </div>
</template>

<script>
export default {
  components: {},
  props: ['uploadImg'],
  data() {
    return {
      //上传路径
      uploadImgUrl: process.env.VUE_APP_URL + '/api/common/uploadFile',
      //上传添加的字段
      uploadImgField: {remarks: 1},
      //图片 Dialog 显示隐藏
      uploadImgVisible: false,
      uploadImgDialogUrl: '',
    };
  },
  mounted() {
  },
  methods: {
    //点击图片放大
    uploadImgClick(url) {
      this.uploadImgVisible = true;
      this.uploadImgDialogUrl = url;
    },
    // 移除文件时
    uploadImgRemove(key) {
      this.$confirm('是否需要删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.uploadImg.img.splice(key, 1);
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //上传失败的钩子
    uploadImgError() {
      this.$message.error('图片上传失败');
    },
    //上传成功钩子
    uploadImgSuccess(file) {
      if (this.uploadImg.isAll) {
        this.uploadImg.img.push(file.data.url);
        this.$message.warning('图片上传成功，还需要提交');
      } else {
        this.uploadImg.img = [];
        this.uploadImg.img.push(file.data.url);
        this.$message.warning('图片上传成功，还需要提交');
      }

    },
    //上传文件之前的钩子
    uploadImgUpload(file) {
      const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
  }
  ,
}
;
</script>

<style lang="scss">
//上传
.upload-img {
  @include flex(nowrap, flex-start, flex-start);

  //上传的框
  .el-upload--picture-card, .el-upload-list__item {
    width: 122px;
    height: 122px;
    line-height: 122px;
    margin: 5px;
  }

  img {
    height: 120px !important;
    width: 120px !important;
    border-radius: 5px;
    cursor: pointer;
  }

  //上传后的图片
  ul {
    @include flex(wrap);

    li {
      border: 1px dashed #c0ccda;
      position: relative;
      border-radius: 5px;
      margin: 5px;

      &:hover {
        border: 1px dashed $color-primary;
      }

      i {
        position: absolute;
        top: 3px;
        right: 3px;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          color: $color-primary;
        }
      }
    }
  }
}

.upload-img-dialog {
  img {
    width: 100%;
  }
}
</style>
