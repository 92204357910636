import Vue from 'vue'
// //全局注册公用组件
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
const context = require.context(
    // 其组件目录的相对路径
    '../../components',
    // 是否查询其子目录
    false,
    // 匹配基础组件文件名的正则表达式
    /\.vue$/
)

context.keys().forEach(fileName => {
    // 获取组件配置
    const componentConfig = context(fileName)
    // 获取组件的 PascalCase 命名
    const componentName = upperFirst(
        camelCase(
            fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
        )
    )
    // 全局注册组件
    Vue.component(
        componentName,
        componentConfig.default || componentConfig
    )
})


