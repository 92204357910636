<template>
  <el-tree
      :ref = "treeData.ref"
      :props="treeData.props"
      :data="treeData.data"
      :default-checked-keys = "treeData.checkId[0]"
      node-key="id"
      show-checkbox
      @check-change="getData">
  </el-tree>
</template>

<script>
export default {
  props: ['treeData'],
  data () {
    return{
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    getData(){
      this.treeData.checkId = [this.$refs[this.treeData.ref].getCheckedKeys(),this.$refs[this.treeData.ref].getHalfCheckedKeys()]
    }
  }
}
</script>

<style scoped>

</style>