import http from '../http';

export function getTemplate (params) {
    return http({
        url: "/api/base/auditTemplatePage",
        method: "get",
        params:params
    })
}
export function getAllTemplate (params) {
    return http({
        url: "/api/base/auditTemplateList",
        method: "get",
        params:params
    })
}

export function addTemplate (data) {
    return http({
        url: "/api/base/auditTemplate",
        method: "put",
        data:data
    })
}
export function deleteTemplate (data) {
    return http({
        url: "/api/base/auditTemplate",
        method: "delete",
        data:data
    })
}
export function editTemplate (data) {
    return http({
        url: "/api/base/auditTemplate",
        method: "post",
        data:data
    })
}

export function getTemplateDetial (params) {
    return http({
        url: "/api/base/auditTemplate",
        method: "get",
        params:params
    })
}

export function getshenPr (params) {
    return http({
        url: "/api/user/allUserName",
        method: "get",
        params:params
    })
}