<template>
  <div class="main">
    <!--<el-scrollbar wrap-class="scrollbar-x">-->
      <div class="main-left">
        <left-layout/>
      </div>
    <!--</el-scrollbar>-->
    <div class="main-right">
<!--      :style="{width:layoutState.isCollapse?'calc(100% - 64px)':'calc(100% - 200px)'}"-->
      <div class="main-right-head" >
        <head-layout/>
      </div>
      <div class="main-right-content">
        <div style="background: white; padding: 12px; border-radius: 10px; margin-bottom: 120px">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftLayout from "./components/LeftLayout";
import HeadLayout from "./components/HeadLayout";
import {mapState} from 'vuex'
import {refresh} from "../api/module/common/common";
export default {
  components: {LeftLayout, HeadLayout},
  props: [],
  data() {
    return {};
  },
  computed: {
    ...mapState('layout', {
      layoutState: state => state
    }),
  },
  created() {
  },
  mounted() {
    // 属性token 半个小时
    setInterval(() => {
      if (localStorage.getItem('admin_token') == null) {
        this.$message.error('请登录账号');
        window.location = window.location.origin + window.location.pathname;
      }
      refresh({}).then(res=>{
        localStorage.setItem('admin_token', res.data.access_token);
      })},60*1000*5);
  },

};
</script>
<style lang="scss" scoped>
.main {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  @include flex(row, nowrap, initial);

  //&-left {
  //  overflow: auto;
  //}
  //&-left::-webkit-scrollbar{
  //  display: none;
  //}

  &-right {
    flex: 1;
    overflow: hidden;
    position: relative;

    &-head {
      position: relative;
      top: 0px;
      z-index: 666;
      color: white;
    }

    &-content {
      position: relative;
      height: calc(100% - 24px);
      background-color: #ECEDFF;
      border-top-left-radius: 10px;
      padding: 12px;
      overflow: auto
      //position: relative;
      //padding: 12px ;
      //background-color: white;
      //border-radius: 10px;
      //margin-bottom: 120px;
    }
  }
}

</style>
