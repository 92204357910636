const layout = {
    //当namespaced=true时vuex,将会自动给各自module添加访问路径名。方便区分moduel
    namespaced: true,
    state: {
        //左边导航 是否缩进
        isCollapse: false,
        //head 面包碎
        breadcrumb: {
            name: '',
            path: ''
        },
        // head tag
        tag: [
            {
                name: '图表',
                path: '/dashboard',
            }
        ]
    },
    mutations: {},
    actions: {
        // dispatch 用于调用action，当前模块和其他模块；
        // aa ({ dispatch, state }, params) {
        //      dispatch('cc')
        // 		dispatch('方法名',{参数}，{ root: true }),//root不是当前模块才要
        // })
    },
    getters: {},
}
export default layout
