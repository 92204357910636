<template>
  <div class="common-add-dialog">
    <div style="border-bottom: 1px solid #b1c6e0; margin: 27px 20px; padding: 10px 0; color: #a5afc8; font-size: 14px;" v-if="shenP.titleType == 1">审批设置</div>
    <template v-else>
      <div v-if="shenP.titleType == 2"></div>
      <div style="border-bottom: 1px solid; margin: 10px 20px; padding: 10px 0" v-else>审批设置</div>
    </template>
    <ul>
      <li>
        <span :style="{'width':shenP.labelWidth}"></span>
        <el-button @click="addDomain">添加审批人</el-button>
      </li>
      <template v-if="shenP.type===1">
        <li v-if="aaa">
          <span :style="{'width':shenP.labelWidth}">审批事项模板</span>
          <el-select v-model="shenP.shenpm" :placeholder="'请选择审批事项模板'" @change="getTemplateDetial(shenP.shenpm)">
            <el-option
                v-for="(itemOption,keyOption) in shenPm"
                :key="keyOption"
                :label="itemOption.name"
                :value="itemOption.id"
                >
            </el-option>
          </el-select>
        </li>
      </template>
      <li v-for="(item,key) in shenP.data" :key="key">
        <span :style="{'width':shenP.labelWidth}">第{{key+1}}审批人</span>

        <el-select v-model="item.user_id" :placeholder="'请选择审批人'">
          <el-option
              v-for="(itemOption,keyOption) in shenPr"
              :key="keyOption"
              :label="itemOption.name"
              :value="itemOption.id">
          </el-option>
        </el-select>

        <el-checkbox-group v-model="item.checked">
          <el-checkbox label="detail" style="display: none">查看</el-checkbox>
          <el-checkbox label="check" @change="isTrue(key)">审批</el-checkbox>
        </el-checkbox-group>
        <el-button @click = "removeDomain(key)">删除</el-button>
      </li>
    </ul>

  </div>
</template>
<script>
import {getAllTemplate, getshenPr, getTemplateDetial} from "@/api/basic_management/template_management";

export default {
  props: ['shenP'],
  comments: {},
  data() {
    return {
      aaa: false,
      shenPr: [],
      shenPm: [],
    }
  },
  created() {
    this.getShenPr()
    this.getAllTemplate()
  },
  mounted() {

  },
  methods: {
    //获取审批人列表
    getShenPr(){
      getshenPr().then((result)=>{
        this.shenPr = result.data
      })
    },
    //添加审批人
    addDomain() {
      this.shenP.data.push({checked: [], user_id: ''});

      let aa = false
      for (let i = 0; i<this.shenP.data.length; i++){
        if (this.shenP.data[i].checked.includes('check')){
          aa = true
          return false
        }
      }
      if (!aa){
          this.shenP.data[0].checked.push("check")
      }
    },
    //移除审批人
    removeDomain(item) {
      this.shenP.data.splice(item, 1)

      if(this.shenP.data.length == 0){
        return false
      }
      let aa = false
      for (let i = 0; i<this.shenP.data.length; i++){
        if (this.shenP.data[i].checked.includes('check')){
          aa = true
          return false
        }
      }
      if (!aa){
        this.shenP.data[0].checked.push("check")
      }
    },
    //获取事项模板
    getAllTemplate(){
      getAllTemplate().then((result)=>{
        this.shenPm = result.data
        this.aaa = true
      })
    },
    //获取模板详情
    getTemplateDetial(id){
      getTemplateDetial({id: id}).then((result)=>{
        this.shenP.data = JSON.parse(result.data.content_json)
      })
    },
    //判断勾选
    isTrue(key){
      let aa = false

      for (let i = 0; i<this.shenP.data.length; i++){
        if (this.shenP.data[i].checked.includes('check')){
          aa = true
          return false
        }
      }

      if (!aa){
        this.$confirm('必须勾选不少于一个审批', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.shenP.data[key].checked.push("check")
        }).catch(()=>{
          this.shenP.data[key].checked.push("check")
        })
      }
    }
  },
}
</script>

<style lang="scss">
.el-checkbox-group{
  display: flex;
  margin-left: 35px;
}
.common-add-dialog {
  li {
    //文本域
    .el-textarea__inner {
      padding: 5px 8px;
    }
  }
}

</style>
<style lang="scss" scoped>
.common-add-dialog {
  li:nth-last-of-type(1){
    margin-bottom: 0;
    align-items: baseline;
  }
  li {
    margin-bottom: 15px;

    span {
      width: 120px;
      text-align: right;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    @include flex();
    //下拉框
    .el-select {
      flex: 1;
    }
    //单选
    .el-radio{
      margin: 3px 30px 3px 0;
    }
    //多选
    .el-checkbox{
      margin: 3px 30px 3px 0;
    }

  }
}
</style>