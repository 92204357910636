<template>
  <div class="common-tabs">
    <el-tabs v-model="tabsProp.active" @tab-click="handleClick">
      <template v-for="(item,key) in tabsProp.tabsData">
        <el-tab-pane
            :key="key"
            :name="item.name"
        >
          <div slot="label" v-if="item.total">
            {{ item.label }}<span>（{{ item.total }}）</span>
          </div>
          <div slot="label" v-else>{{ item.label }}</div>
        </el-tab-pane>
      </template>
    </el-tabs>
    <div class="common-tabs-right">
      <el-input
          placeholder="请输入"
          v-model="tabsProp.keywords"
          class="key-word"
          v-if="tabsProp.keywordsShow"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="$emit(tabsProp.keyWordMethod)"></i>
      </el-input>
      <div class="common-tabs-right-search" v-if="tabsProp.isHighKeyWord">
        <el-button type="primary" @click="highKeyWordShowClick">
          高级搜索
        </el-button>
      </div>
    </div>

    <!--点击高级搜索 -->
    <el-collapse-transition>
      <div v-if="highKeyWordShow">
        <ul class="high-KeyWord-Show">
          <template v-for="(item,key) in tabsProp.highKeyWord">
            <!--输入框-->
            <li :key="key" v-if="item.type==1">
              <span>{{ item.name }}</span>
              <el-input v-model="tabsProp.heighKewWordField[item.field]" :placeholder="'请输入'+ item.name"></el-input>
            </li>
            <!--下拉框-->
            <li :key="key" v-if="item.type==2">
              <span>{{ item.name }}</span>
              <el-select v-model="tabsProp.heighKewWordField[item.field]" clearable :placeholder="'请选择'+ item.name">
                <el-option
                    v-for="ite in tabsProp[item.field+'Options']"
                    :key="ite.value"
                    :label="ite.label"
                    :value="ite.value">
                </el-option>
              </el-select>
            </li>
            <!--下拉框有改变值的方法-->
            <li :key="key" v-if="item.type==3">
              <span>{{ item.name }}</span>
              <el-select v-model="tabsProp.heighKewWordField[item.field]" clearable placeholder="请选择"
                         @change="$emit(item.field+'Change',tabsProp.heighKewWordField[item.field])">
                <el-option
                    v-for="itemSelect in tabsProp[item.field+'Options']"
                    :key="itemSelect.value"
                    :label="itemSelect.label"
                    :value="itemSelect.value">
                </el-option>
              </el-select>
            </li>
            <!--单个时间-->
            <li :key="key" v-if="item.type==4">
              <span>{{ item.name }}</span>
              <el-date-picker
                  v-model="tabsProp.heighKewWordField[item.field]"
                  type="date"
                  placeholder="选择日期"
                  value-format="timestamp"
              >
              </el-date-picker>
            </li>

            <li :key="key" v-if="item.type==400">
              <span>{{ item.name }}</span>
              <el-date-picker
                  v-model="tabsProp.heighKewWordField[item.field]"
                  :type="item.getType"
                  placeholder="选择日期"
                  value-format="timestamp"
              >
              </el-date-picker>
            </li>

            <!--时间范围-->
            <li :key="key" v-if="item.type==5">
              <span>{{ item.name }}</span>
              <el-date-picker
                  v-model="tabsProp.heighKewWordField[item.field]"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="timestamp"
                  :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </li>
            <!--下拉框有改变值的方法-->
            <li :key="key" v-if="item.type==7">
              <span>{{ item.name }}</span>
              <el-select v-model="tabsProp.heighKewWordField[item.field]" clearable placeholder="请选择"
                         @change="$emit(item.field+'Change',tabsProp.heighKewWordField[item.field])">
                <el-option
                    v-for="itemSelect in tabsProp[item.field+'Options']"
                    :key="itemSelect.value"
                    :label="itemSelect.label"
                    :value="itemSelect.value">
                </el-option>
              </el-select>
            </li>


            <!--单选框 先不用-->
            <!--<li :key="key" v-if="item.type==6">
              <span>{{ item.name }}</span>
              <el-radio-group v-model="tabsProp.heighKewWordField[item.field]">
                <template v-for="(itemRadio,itemRadioKey) in tabsProp[item.field+'Radio']">
                  <el-radio :key="itemRadioKey" :label="itemRadio.value">
                    {{ itemRadio.label }}
                  </el-radio>
                </template>
              </el-radio-group>
            </li>-->
          </template>
          <li style="margin-left: 15px">
            <el-button type="primary" icon="el-icon-search el-icon--left" @click="$emit('highKeyWordMethod')">搜索</el-button>
          </li>
        </ul>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['tabsProp'],
  data() {
    return {
      highKeyWordShow: false
    };
  },
  mounted() {
  },
  methods: {
    //tabs 点击
    handleClick(tab, event) {
      this.$emit('tabsClick', tab, event)
    },
    //tabs 高级搜索 点击显示隐藏
    highKeyWordShowClick() {
      this.highKeyWordShow = !this.highKeyWordShow
    },

  },
};
</script>

<style lang="scss" scoped>

.common-tabs {
  position: relative;

  &-right {
    position: absolute;
    top: 0px;
    right: 0;
    @include flex();

    &-search {
      margin-left: 8px;
    }
  }
}

.high-KeyWord-Show {
  overflow: hidden;
  @include flex(wrap);
  li {
    float: left;
    @include flex();
    width: 300px;
    span {
      flex:0 0 auto;
      margin: 12px;
      width: 70px;
      text-align: end;
    }
    .el-date-editor{width: 300px}
  }
  margin-bottom: 12px;
}
</style>
