import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//整块板块布局
import Layout from '@/layout'
// import Home from '@/layout/home'
// import tableRouter from './module/table'
//
const routes = [
    {
        path: '/',
        redirect: '/login',
        meta: {authority: [0]},
    },
    {
        path: '/login',
        name: 'Login',
        meta: {authority: [0]},
        component: () => import(/* webpackChunkName: "login" */ 'views/login'),
    },
    {
        path: '/leadership',
        name: 'Leadership',
        meta: {authority: [0]},
        component: () => import(/* webpackChunkName: "login" */ 'views/leadership'),
    },
    {
        path: '/project_management/my_project/accept',
        name: 'accept',
        meta: {authority: [0]},
        component: () => import(/* webpackChunkName: "accept" */ 'views/project_management/my_project/accept')
    },
    {
        path: '/project_management/my_project/progress_report',

        name: 'progress_report',
        meta: {authority: [0]},
        component: () => import(/* webpackChunkName: "accept" */ 'views/project_management/my_project/progress_report')
    },
    {
        path: '/project_management/my_project/pleased',
        name: 'pleased',
        meta: {authority: [0]},
        component: () => import(/* webpackChunkName: "pleased" */ 'views/project_management/my_project/pleased')
    },
    {
        path: '/register',
        name: 'Register',
        meta: {authority: [0]},
        component: () => import(/* webpackChunkName: "register" */ 'views/register')
    },
    {
        path: '/register/perfectInfo',
        name: 'perfectInfo',
        meta: {authority: [0]},
        component: () => import(/* webpackChunkName: "login" */ 'views/register/perfectInfo')
    },
    {
        path: '/home',
        component: Layout,
        name: '首页',
        redirect: '/home/Home',
        meta: {title: '首页', authority: [100], icon: '00', id: 20000, onlyOne: 1},
        children: [
            {
                path: 'Home',
                name: 'Home',
                meta: {title: 'title', authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "login" */ 'views/home'),
            },
            {
                path: 'announcement',
                name: 'announcement',
                meta: {authority: [0]},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/home/announcement.vue')
            },
            {
                path: 'message',
                name: 'message',
                meta: {authority: [0]},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/home/message.vue')
            }]
    },


    /*基础管理*/
    {
        path: '/basic_management',
        component: Layout,
        redirect: '/basic_management/organizational_structure',
        name: 'basicManagement',
        meta: {title: '组织结构', name: "组织结构", icon: '01', authority: [100], id: 20000},
        children: [
            {
                path: 'organizational_structure',
                name: 'organizationalStructure',
                meta: {title: '组织结构', name: "组织结构", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "organizationalStructure" */ 'views/basic_management/organizational_structure')
            }, {
                path: 'project_type',
                name: 'projectType',
                meta: {title: '项目类型设置', name: "项目类型设置", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "projectType" */ 'views/basic_management/project_type')
            }, {
                path: 'permission_settings',
                name: 'permissionSettings',
                meta: {title: '权限设置', name: "权限设置", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "permissionSettings" */ 'views/basic_management/permission_settings')
            }, {
                path: 'template_management',
                name: 'templateManagement',
                meta: {title: '事项模板管理', name: "事项模板管理", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "templateManagement" */ 'views/basic_management/template_management')
            }
        ]
    },
    /*员工管理*/
    {
        path: '/staff',
        component: Layout,
        redirect: '/staff/index',
        name: 'staff',
        meta: {title: '员工管理', name: "员工管理", icon: '02', authority: [100], id: 20000},
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {title: '员工列表', name: "员工列表", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/staff/index')
            },
            {
                path: 'index/edit',
                name: 'index/edit',
                meta: {authority: [0], isShow: true},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/staff/index/edit')
            },

            {
                path: 'my_center',
                name: 'my_center',
                meta: {title: '我的主页', name: "我的主页", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/staff/my_center')
            },
            {
                path: 'my_center/regular',
                name: 'my_center/regular',
                meta: {authority: [0], isShow: true},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/staff/my_center/regular')
            },
            {
                path: 'my_center/regular/record',
                name: 'my_center/regular/record',
                meta: {authority: [0], isShow: true},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/staff/my_center/regular/record')
            },
            {
                path: 'my_center/bonus',
                name: 'my_center/bonus',
                meta: {authority: [0], isShow: true},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/staff/my_center/bonus')
            },
            {
                path: 'my_center/salary',
                name: 'my_center/salary',
                meta: {authority: [0], isShow: true},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/staff/my_center/salary')
            },
            {
                path: 'my_center/salary/salary_change',
                name: 'my_center/salary/salary_change',
                meta: {authority: [0], isShow: true},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/staff/my_center/salary/salary_change')
            },

            {
                path: 'my_center/leave',
                name: 'my_center/leave',
                meta: {authority: [0], isShow: true},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/staff/my_center/leave')
            },
            {
                path: 'my_center/grow',
                name: 'my_center/grow',
                meta: {authority: [0], isShow: true},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/staff/my_center/grow')
            },
            {
                path: 'my_center/reimbursement',
                name: 'my_center/reimbursement',
                meta: {authority: [0], isShow: true},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/staff/my_center/reimbursement')
            },
            {
                path: 'my_center/reimbursement/normal',
                name: 'my_center/reimbursement/normal',
                meta: {authority: [0], isShow: true},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/staff/my_center/reimbursement/normal')
            },
            {
                path: 'my_center/reimbursement/travel',
                name: 'my_center/reimbursement/travel',
                meta: {authority: [0], isShow: true},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/staff/my_center/reimbursement/travel')
            },
            {
                path: 'my_center/reimbursement/detail',
                name: 'my_center/reimbursement/detail',
                meta: {authority: [0], isShow: true},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/staff/my_center/reimbursement/detail')
            },


        ]
    },
    //
    /*项目管理*/
    {
        path: '/project_management',
        component: Layout,
        redirect: '/project_management/new_project',
        name: 'projectManagement',
        meta: {title: '项目管理', name: "项目管理", icon: '03', authority: [100], id: 20000},
        children: [
            {
                path: 'business_management',
                name: 'businessManagement',
                meta: {title: '商机管理', name: "商机管理", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProject" */ '@/views/project_management/business_management')
            },
            {
                //项目集已删除，保留页面与路由，如需重启只需在数据库中加入权限即可
                path: 'project_set',
                name: 'projectSet',
                meta: {title: '项目集管理', name: "项目集管理", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProject" */ '@/views/project_management/project_set')
            },
            {
                path: 'new_project',
                name: 'newProject',
                meta: {title: '新建项目', name: "新建项目", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProject" */ '@/views/project_management/new_project')
            },
            {
                path: 'project_list',
                name: 'projectList',
                meta: {title: '项目列表', name: "项目列表", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "projectList" */ 'views/project_management/project_list')
            },
            {
                path: 'settlement',
                name: 'settlement',
                meta: {title: '项目结算', name: "项目结算", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProjecsstbdfb" */ 'views/project_management/settlement')
            },
            {
                path: 'settlement/detail',
                name: 'settlement',
                meta: {title: '项目结算明细', name: "项目结算明细", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProssdjectbdfb" */ 'views/project_management/settlement/detail')
            },
            // {
            //     path: 'projects_pending',
            //     name: 'projectsPending',
            //     meta: {title: '待揭榜项目', name: "待揭榜项目", authority: [100], id: 20000},
            //     component: () => import(/* webpackChunkName: "projectsPending" */ 'views/project_management/projects_pending')
            // }, {
            //     path: 'project_execution_summary',
            //     name: 'projectExecutionSummary',
            //     meta: {title: '项目执行汇总表', name: "项目执行汇总表", authority: [100], id: 20000},
            //     component: () => import(/* webpackChunkName: "projectExecutionSummary" */ 'views/project_management/project_execution_summary')
            // },
            {
                path: 'working_saturation',
                name: 'workingSaturation',
                meta: {title: '工作饱和度情况表', name: "工作饱和度情况表", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "workingSaturation" */ 'views/project_management/working_saturation')
            }, {
                path: 'dividend_leaderboard',
                name: 'dividendLeaderboard',
                meta: {title: '分红排行榜', name: "分红排行榜", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "dividendLeaderboard" */ 'views/project_management/dividend_leaderboard')
            }, {
                path: 'growth_value_summary',
                name: 'growthValueSummary',
                meta: {title: '成长值汇总表', name: "成长值汇总表", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "growthValueSummary" */ 'views/project_management/growth_value_summary')
            },
            // {
            //     path: 'my_project',
            //     name: 'myProject',
            //     meta: {title: '我的项目', name: "我的项目", authority: [100], id: 20000},
            //     component: () => import(/* webpackChunkName: "myProject" */ 'views/project_management/my_project')
            // },
            {
                path: 'project_detail',
                name: 'project_detail',
                meta: {authority: [0], isShow: true},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/project_management/project_detail.vue')
            },
        ]
    },



    /*项目管理*/
    {
        path: '/business_management',
        component: Layout,
        redirect: '/business_management/new_business',
        name: 'businessManagement',
        meta: {title: '商贸管理', name: "商贸管理", icon: '03', authority: [100], id: 20000},
        children: [
            {
                path: 'business_list',
                name: 'businessList',
                meta: {title: '商贸列表', name: "商贸列表", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "projectList" */ 'views/business_management/business_list')
            },
            {
                path: 'add',
                name: 'add',
                meta: {title: '新增', name: "新增", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "projectList" */ 'views/business_management/add')
            },
            {
                path: 'details',
                name: 'details',
                meta: {title: '商贸信息', name: "商贸信息", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "projectList" */ 'views/business_management/details')
            },
            {
                path: 'order',
                name: 'order',
                meta: {title: '商贸成单', name: "商贸成单", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "projectList" */ 'views/business_management/order')
            },

            {
                path: 'business_total',
                name: 'business_total',
                meta: {title: '商贸统计', name: "商贸统计", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "projectList" */ 'views/business_management/business_total')
            },


        ]
    },

    /*审批管理*/
    {
        path: '/approval',
        component: Layout,
        redirect: '/approval/project',
        name: 'approval',
        meta: {title: '审批管理', name: "审批管理", icon: '04', authority: [100], id: 20000},
        children: [
            {
                path: 'project_approval',
                name: 'project_approval',
                meta: {title: '立项项目审批', name: "立项项目审批", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProject" */ 'views/approval/project_approval')
            },
            {
                path: 'single_project',
                name: 'single_project',
                meta: {title: '成单项目审批', name: "成单项目审批", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProject" */ 'views/approval/single_project')
            },
            {
                path: 'assign',
                name: 'assign',
                meta: {title: '项目指派', name: "项目指派", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProject" */ 'views/approval/assign')
            },
            {
                path: 'check',
                name: 'check',
                meta: {title: '验收审核', name: "验收审核", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newPrvdsvoject" */ 'views/approval/check')
            },{
                path: 'team_change',
                name: 'team_change',
                meta: {title: '成员变更审核', name: "验收审核", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newPrvdsvoject" */ 'views/approval/team_change')
            },
            {
                path: 'assign/edit',
                name: 'assign/edit',
                meta: {authority: [0], isShow: true},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/approval/assign/edit')
            },
            {
                path: 'unveiling',
                name: 'unveiling',
                meta: {title: '市场部项目结题', name: "市场部项目结题", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProject" */ 'views/approval/unveiling')
            },
            {
                path: 'dev_unveiling',
                name: 'dev_unveiling',
                meta: {title: '技术部项目结题', name: "技术部项目结题", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProject" */ 'views/approval/dev_unveiling')
            },
            {
                path: 'reimburse',
                name: 'reimburse',
                meta: {title: '审批-报销', name: "审批-报销", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProject" */ 'views/approval/reimburse')
            }, {
                path: 'worker',
                name: 'worker',
                meta: {title: '审批-转正', name: "审批-转正", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProject" */ 'views/approval/worker')
            }, {
                path: 'business_approval',
                name: 'business_approval/index',
                meta: {title: '商贸订单审批', name: "商贸订单审批", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProject" */ 'views/approval/business_approval/index')
            }, {
                path: 'business_approval/details',
                name: 'business_approval/details',
                meta: {title: '商贸审批', name: "商贸审批", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProject" */ 'views/approval/business_approval/details')
            },{
                path: 'business_chengdan',
                name: 'business_approval/business_chengdan',
                meta: {title: '商贸成单审批', name: "商贸成单审批", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProject" */ 'views/approval/business_approval/business_chengdan')
            },
            {
                path: 'business_approval/chengdan_details',
                name: 'business_approval/chengdan_details',
                meta: {title: '商贸成单审批', name: "商贸成单审批", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProject" */ 'views/approval/business_approval/chengdan_details')
            },
            {
                path: 'leave',
                name: 'leave',
                meta: {title: '离职审批', name: "离职审批", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProject" */ 'views/approval/leave')
            },

        ]
    },
    /*物资管理*/
    {
        path: '/material',
        component: Layout,
        redirect: '/material/project',
        name: 'material',
        meta: {title: '物资管理', name: "物资管理", icon: '04', authority: [100], id: 20000},
        children: [
            {
                path: 'project',
                name: 'project',
                meta: {title: '项目物资管理', name: "项目物资管理", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "operationLog" */ 'views/material/project')
            },
        ]
    },
    //
    // /*报表统计*/
    {
        path: '/report_form',
        component: Layout,
        redirect: '/report_form/leave',
        name: 'reportForm',
        meta: {title: '报表统计', name: "报表统计", icon: '05', authority: [100], id: 20000},
        children: [
            {
                path: 'leave',
                name: 'leave',
                meta: {title: '请假记录', name: "请假记录", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/report_form/leave')
            },
            {
                path: 'salary',
                name: 'salary',
                meta: {title: '工资单汇总', name: "工资单汇总", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProjectvf" */ 'views/report_form/salary')
            },
            {
                path: 'reimburse',
                name: 'reimburse',
                meta: {title: '报销单汇总', name: "报销单汇总", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "newProjectbdgbd" */ 'views/report_form/reimburse')
            },
            // {
            //     path: 'settlement',
            //     name: 'settlement',
            //     meta: {title: '项目结算', name: "项目结算", authority: [100], id: 20000},
            //     component: () => import(/* webpackChunkName: "newProjectbdfb" */ 'views/report_form/settlement')
            // },
            // {
            //     path: 'settlement/detail',
            //     name: 'settlement',
            //     meta: {title: '项目结算明细', name: "项目结算明细", authority: [100], id: 20000},
            //     component: () => import(/* webpackChunkName: "newProjectbdfb" */ 'views/report_form/settlement/detail')
            // },

        ]
    },
    //
    /*文档管理*/
    {
        path: '/document_management',
        component: Layout,
        redirect: '/document_management/contract_template',
        name: 'documentManagement',
        meta: {title: '文档管理', name: "文档管理", icon: '06', authority: [100], id: 20000},
        children: [
            {
                path: 'company_document',
                name: 'companyDocument',
                meta: {title: '公司管理文档', name: "公司管理文档", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "companyDocument" */ 'views/document_management/company_document')
            },
            {
                path: 'contract_template',
                name: 'contractTemplate',
                meta: {title: '项目管理文档', name: "项目管理文档", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "contractTemplate" */ 'views/document_management/contract_template')
            },
            {
                path: 'detail',
                name: 'detail',
                meta: {authority: [0], isShow: true},
                component: () => import(/* webpackChunkName: "newProjects" */ 'views/document_management/contract_template/detail.vue')
            },
            {
                path: 'functional_module',
                name: 'functionalModule',
                meta: {title: '功能模块库', name: "功能模块库", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "contractTemplate" */ 'views/document_management/functional_module')
            },
            {
                path: 'project_reimburse_file',
                name: 'projectReimburseFile',
                meta: {title: '报销附件', name: "报销附件", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "contractTemplate" */ 'views/document_management/project_reimburse_file')
            },
            {
                path: 'project_reimburse_file/detail',
                name: 'projectReimburseFile/detail',
                meta: {title: '报销附件', name: "报销附件", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "contractTemplate" */ 'views/document_management/project_reimburse_file/detail')
            },
        ]
    },

    /*公告管理*/
    {
        path: '/notice_management',
        component: Layout,
        redirect: '/notice_management/notice_management',
        name: 'noticeManagement',
        meta: {title: '公告管理', name: "公告管理", icon: '07', authority: [100], id: 20000},
        children: [
            {
                path: 'notice_management',
                name: 'notice_management',
                meta: {title: '公告列表', name: "公告列表", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "noticeManagement" */ 'views/notice_management/notice_management')
            },
        ]
    },

    /*操作日志管理*/
    {
        path: '/log_management',
        component: Layout,
        redirect: '/log_management/operation_log',
        name: 'logManagement',
        meta: {title: '操作日志管理', name: "操作日志管理", icon: '08', authority: [100], id: 20000},
        children: [
            {
                path: 'operation_log',
                name: 'operationLog',
                meta: {title: '操作日志', name: "操作日志", authority: [100], id: 20000},
                component: () => import(/* webpackChunkName: "operationLog" */ 'views/log_management/operation_log')
            },
        ]
    },

]
// const menuListJson = JSON.parse(localStorage.getItem('menuList'));
// let menuList = [];
// if(menuListJson!=null){
//     for (let i = 0; i < 1; i++) {
//         let menuItem = {};
//         menuItem.path = menuListJson[i].path
//         menuItem.component = Layout
//         menuItem.redirect = menuListJson[i].remark_data
//         menuItem.name = menuListJson[i].path
//
//         let meta = {};
//         meta.title = menuListJson[i].menu_name
//         meta.authority = [100]
//         meta.icon = menuListJson[i].icon
//         meta.id = menuListJson[i].id
//         if (menuListJson[i].menu_level == 1){
//             meta.onlyOne = 1
//         }
//         menuItem.meta = meta
//         let childList = []
//         for (let j = 0; j < menuListJson[i].children.length; j++) {
//             let secondsItem = {}
//             if (menuListJson[i].children[j].remark_data){
//                 secondsItem.path = menuListJson[i].children[j].remark_data
//                 secondsItem.name = menuListJson[i].children[j].menu_name
//                 let secondMata = {}
//                 secondMata.title = secondMata.name = menuListJson[i].children[j].menu_name
//                 secondMata.authority = [100]
//                 secondMata.id = menuListJson[i].children[j].id
//                 secondsItem.meta = secondMata
//                 secondsItem.component = require( '../views'+menuListJson[i].children[j].remark_data).default
//             }
//             childList.push(secondsItem);
//
//         }
//         menuItem.children = childList
//
//         menuList.push(menuItem);
//     }
// }
//
// console.log(menuList)

// const router = new VueRouter({
//     menuList
// })
const router = new VueRouter({
    routes
})

// router.addRoutes(menuList)


//解决重复点击路由报错
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}

export default router
