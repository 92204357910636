import Vue from 'vue'
import App from './App.vue'
import router from 'router'
import store from 'store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//主css
import 'assets/css/index.scss';
//svg文件夹
import 'icons'
//加载路由守卫
import 'assets/utils/routerGuard'

// 水波纹指令  使用 v-waves
import waves from '@/directive/waves/index.js'
waves.install(Vue)
//注册全局组件
import '@/assets/utils/signinComponents';
//公用类
import Utils from "@/assets/utils/utils";
//复制
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.prototype.Utils = Utils;

Vue.use(ElementUI, {
    size: 'small'
});

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
