<template>
  <div>
    <!-- 表头字体颜色 :header-cell-style="{
     'color': '#18a78a'}"-->
    <el-table
        ref="table"
        :data="tableData"
        border
        style="width: 100%"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
        v-loading="$store.state.loading"
    >
      <!--序号-->
      <el-table-column
          v-if="tableProp.index"
          width="55"
          label="序号"
          type="index"
          align="center"
      >
      </el-table-column>
      <!--多选框-->
      <el-table-column
          v-if="tableProp.selection"
          width="55"
          type="selection"
          align="center"
      >
      </el-table-column>
      <!--循环出来的字段-->
      <template v-for="(item,key) in tableProp.table">
        <el-table-column
            :key="key"
            :label="item.title"
            :width="item.isWidth?item.width:'auto'"
            :min-width="!item.isWidth?item.width:'auto'"
            :align="item.align"
            :header-align="item.headerAlign"
            :show-overflow-tooltip="!item.tooltip"
        >
          <template slot-scope="scope">

            <div v-if="item.type==0">
              {{ scope.row[item.field] }}
            </div>

            <!--图片-->
            <div v-if="item.type===1" class="table-img">
              <ul v-if="scope.row[item.field]!=null">
                <li v-for="(itemImg, keyImg) in scope.row[item.field].split(',')" :key="keyImg">
                  <img :src="itemImg" @click="tableImgClick(itemImg)">
                </li>
              </ul>
              <div v-else>

              </div>
            </div>

            <!--操作-->
            <div v-if="item.type===2" class="table-button">
              <ul>
                <li v-for="(itemBut,keyBut) in item.tableOperate" :key="keyBut">
                  <!--{{item.tableOperate}}-->
                  <!--权限id不是2000要去对比是否有权限  2000直接过不要对比-->
                  <template v-if="itemBut.id!=20000">
                    <template v-if="itemBut.status.split(',').indexOf('20000')===-1">
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="itemBut.status.split(',').indexOf(scope.row[tableProp.statusName].toString())!==-1 && $store.state.routerRole.indexOf(itemBut.id)!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>
                    <template v-else>
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="$store.state.routerRole.indexOf(itemBut.id)!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>
                  </template>

                  <template v-else>
                    <template v-if="itemBut.status.split(',').indexOf('20000')===-1">
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                          v-if="itemBut.status.split(',').indexOf(scope.row[tableProp.statusName].toString())!==-1"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>
                    <template v-else>
                      <el-button
                          :type="itemBut.type"
                          @click="$emit(itemBut.method,scope.row,scope.$index)"
                      >
                        {{ itemBut.name }}
                      </el-button>
                    </template>
                  </template>
                </li>
              </ul>
            </div>
            <!--json-->
            <div v-if="item.type==3">
              <span>
                 {{ scope.row[item.field]==null? '': (scope.row[item.field].name == null? '': scope.row[item.field].name)}}
              </span>
            </div>
            <!--时间戳-->
            <div v-if="item.type==4">
              <span v-if="scope.row[item.field]">

                 {{ Utils.timeDate(scope.row[item.field]) }}
              </span>
              <span v-else>
                  未设置
              </span>
            </div>

            <div v-if="item.type==40">
              <span v-if="(scope.row[item.field])">
                  <span v-if="(scope.row[item.field]).toString().length>=13">
                    {{ Utils.timeDate(parseInt(scope.row[item.field])/1000) }}
                  </span>
                  <span v-else>
                    {{ Utils.timeDate(scope.row[item.field]) }}
                  </span>

              </span>
              <span v-else>
                  未设置
              </span>
            </div>

          </template>
        </el-table-column>
      </template>
    </el-table>

    <!--分页-->
    <div class="pagination" v-if="tableProp.isPage">
      <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="tableProp.page.current_page"
          :page-size="tableProp.page.size"
          layout="total, prev, pager, next"
          :total="tableProp.page.total">
      </el-pagination>
    </div>

    <!--点击图片 dialog-->
    <el-dialog
        :append-to-body="true"
        ref="tableImg"
        title="图片展示"
        :visible.sync="tableImgDialogVisible"
        width="80%"
        top="20px"
        custom-class="table-img-dialog"
    >
      <img :src="tableImgDialogUrl">
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['tableData', 'tableProp'],
  data() {
    return {
      //图片 Dialog 显示隐藏
      tableImgDialogVisible: false,
      tableImgDialogUrl: '',
    };
  },
  mounted() {
  },
  methods: {
    //分页
    handleCurrentChange(val) {
      this.$emit(this.tableProp.page.method, val)
    },
    //表格 多选后的值
    handleSelectionChange(selection) {
      let tableDataROW = [];
      selection.forEach((value) => {
        this.tableData.forEach((val) => {
          if (value[this.tableProp.selectionVal] == val[this.tableProp.selectionVal]) {
            tableDataROW.push(value[this.tableProp.selectionVal]);
          }
        });
      });
      //把多选完后的数组保存起来
      this.tableProp.selectionId = [];
      this.tableProp.selectionId = tableDataROW;
      console.log(this.tableProp.selectionId)
    },
    //图片 点击
    tableImgClick(url) {
      this.tableImgDialogVisible = true;
      this.tableImgDialogUrl = url;
    },
    //表格 第几行显示什么颜色
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
//图片
.table-img {
  ul {
    @include flex(wrap);
  }

  img {
    height: 60px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
  }
}

//图片 dialog
.table-img-dialog {
  img {
    width: 100%;
  }
}

//操作
.table-button {
  ul {
    @include flex(wrap,center);
    li {
      .el-button--small {
        margin: 5px;
      }
    }
  }
}

//分页
//分页
.pagination {
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(100% - 248px);
  padding: 12px;
  @include flex(nowrap, flex-end);
  z-index: 999;
  border-radius: 10px;
}

</style>
