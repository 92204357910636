import axios from 'axios';
// import router from 'router';
import store from '@/store'
import { Message } from 'element-ui';
// import {isLogin} from "./module/login";



const http = axios.create({
    /*访问地址 开发环境要加 '/api 生成环境只要 '/' 就可以了*/
    timeout: 10000,
    //baseURL: process.env.NODE_ENV === "development" ? '/api' : '/',
    baseURL: process.env.NODE_ENV === "development" ? '/api' : '/',

    // 其他axios的配置详见：https://www.kancloud.cn/yunye/axios/234845
});

// 添加请求拦截器
http.interceptors.request.use(
    config => {
        // 在请求发送前做些什么
        // alert(localStorage.getItem('admin_token'))
        // isLogin().then(res=>{
        //     console.log(res)
        // })

        // 例如添加自定义请求头:
        config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('admin_token');
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//响应拦截器
http.interceptors.response.use(
    response => {
        //返回的code不等于20000就会提示错误，里面可以写弹出等提示 否则返回 res.data
        const res = response.data
        store.state.loading = false;
        return res
    },
    error => {
        let msg = ""
        if(error.response.data.msg!=undefined){
            msg = error.response.data.msg
        }
        if(error.response.data.message!=undefined){
            msg = error.response.data.message
        }
        if(error.response.status==422){
            msg = "服务器超时或参数错误，请检查"
        }
        Message({
            message: msg,
            type: 'error',
            duration: 3 * 1000
        })
        if(error.response.status==401){
            console.log(error.response)
            window.location = window.location.origin + window.location.pathname;
        }
        return Promise.reject(error);
    }
);

export default http;
