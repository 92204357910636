import http from '../../http';

export function getList(params) {
    return http({//获取用户列表
        url: "/api/user/userList",
        method: "get",
        params: params
    })
}

export function getDetail(params) {
    return http({//获取用户列表
        url: "/api/user/userDetailUserInfo",
        method: "get",
        params: params
    })
}

export function departmentList(params) {
    return http({//获取部门列表
        url: "/api/user/oneDepartment",
        method: "get",
        params: params
    })
}


// export function departmentList (params) {
//     return http({//获取部门列表
//         url: "/api/user/departmentList",
//         method: "get",
//         params:params
//     })
// }

export function oneDepartment(params) {
    return http({//获取部门信息
        url: "/api/user/oneDepartment",
        method: "get",
        params: params
    })
}

export function twoDepartment(params) {
    return http({//获取岗位名称
        url: "/api/user/twoDepartment",
        method: "get",
        params: params
    })
}

export function myLeadProject(params) {
    return http({//获取岗位名称
        url: "/api/project/myLeadProject",
        method: "get",
        params: params
    })
}



export function positionsSameLevel(params) {
    return http({//获取岗位名称
        url: "/api/user/positionsSameLevel",
        method: "get",
        params: params
    })
}


export function getUserFromStation(params) {
    return http({//获取岗位名称
        url: "/api/ltd/getUserFromStation",
        method: "get",
        params: params
    })
}


export function editUserSalary(data) {
    return http({//设置薪资福利
        url: "/api/ltd/userSalary",
        method: "post",
        data: data
    })
}

export function getUserGroup(params) {
    return http({//获取用户组权限
        url: "/api/ltd/userGroupAll",
        method: "get",
        params: params
    })
}


export function edituserStatus(data) {
    return http({//设置岗位
        url: "/api/user/userStatus",
        method: "post",
        data: data
    })
}

export function editUserStation(data) {
    return http({//设置岗位
        url: "/api/ltd/userStation",
        method: "post",
        data: data
    })
}

export function editUserInfo(data) {
    return http({
        url: "/api/ltd/userInfo",
        method: "post",
        data: data
    })
}

export function editUserEdu(data) {
    return http({
        url: "/api/ltd/userEducationUserCreateAndUpdate",
        method: "put",
        data: data
    })
}

export function editUserWork(data) {
    return http({
        url: "/api/ltd/userWorkUserCreateAndUpdate",
        method: "put",
        data: data
    })
}

export function myMemberProject(params) {
    return http({//获取用户组权限
        url: "/api/project/myMemberProject",
        method: "get",
        params: params
    })
}

export function getAllProjectList(params) {
    return http({//获取用户组权限
        url: "/api/project/projectList",
        method: "get",
        params: params
    })
}







